.main-wrapper {
    .home-link-list {
        .justify-content-center {
            justify-content: center !important;

            .fixed-tab {
                ul {
                    li {
                        padding: 0.5rem;
                        list-style: none;
                    }
                }

                h2 {
                    text-align: center;
                    font-weight: bold;
                    font-size: 34px;
                    line-height: 50px;
                    margin-bottom: 20px;
                    color: #003049;
                }
            }

            form {
                margin: 0 19rem;

                .form-group {
                    input {
                        border: 0.5px solid #959595;
                        border-radius: 50px;
                        color: #959595;
                        font-size: 16px;
                        font-style: normal;
                        width: 100%;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        line-height: 32px;
                        margin-bottom: 25px;
                        padding: 0 1.6rem;
                    }
                    input:-webkit-autofill {
                        transition: background-color 9999s ease-in-out 0s;
                        -webkit-text-fill-color: var(--text-black)!important;
                    }

                    input:focus-visible {
                        outline: 0;
                    }

                    span {
                        position: absolute;
                        margin: 13px 0;
                        right: 33%;
                    }
                    .quation-img {
                        margin: 10px 0px;
                    }
                    .input-icon {
                        position: absolute;
                        margin: 18px;
                    }
                    .material-icons {
                        padding: 0 55px;
                    }
                    .content {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        text-align: left;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 14px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }
                        .checkmark {
                            position: absolute;
                            top: 5px;
                            left: 0;
                            margin: 4px 0;
                            height: 27px;
                            border: 2px solid #959595;
                            width: 27px;
                            border-radius: 50px;
                            background-color: #fff;
                        }
                        input:checked ~ .checkmark {
                            background-color: #fff;
                        }
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                        input:checked ~ .checkmark:after {
                            display: block;
                        }
                        .checkmark:after {
                            left: 3px;
                            top: 3px;
                            width: 17px;
                            height: 17px;
                            background-color: #959595;
                           border-radius: 50px;
                        }                 
                    }
                }
                .card-icon {
                    position: relative;
                    bottom: 5px;
                    height: 36px;
                    display: block;
                    img {
                        margin-right: 10px;
                    }
                    b {
                        font-size: 14px;
                        font-weight: 400;
                        color: #959595;
                    }
                }

                h2 {
                    text-align: center;
                    font-family: Corbel;
                    font-weight: bold;
                    font-size: 34px;
                    margin-bottom: 2rem;
                    color: #003049;
                }

                p {
                    text-align: center;
                    margin: 0rem 0 1rem;
                    line-height: 11px;
                }

                .form-check {
                    padding: 0;

                    p {
                        font-size: 16px;
                        margin: 1rem 0;

                        a {
                            font-weight: 600;
                            color: #444444;
                        }
                    }
                }

                .get-in-btn {
                    margin: 0.5rem 0 2.5rem;

                    button {
                        color: #fff;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #D62828;
                        border-radius: 50px;
                        width: 100%;
                        background-color: #D62828;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        font-weight: 500;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                        span {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .home-link-list {
            .justify-content-center {
                form {
                    margin: 0 auto;
                    width: 54%;

                    .form-group span {
                        right: 28%;
                    }

                    .get-in-btn {
                        button {
                            width: 43%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 991px) {
    .main-wrapper {
        .home-link-list {
            .justify-content-center {
                form {
                    margin: 0 auto;
                    width: 100%;

                    .form-group span {
                        right: 8%;
                    }
                    .form-group {
                        .text-center {
                            text-align: left !important;
                        }
                        .cvc-text {
                            width: 94%;
                        }
                        .quation-img {
                            position: absolute;
                            right: 37px;
                            margin: -62px 0;
                        }
                        .material-icons {
                            width: 95%;
                            margin-left: 35px;
                        }
                        .input-icon {
                            left: 70px;
                        }
                        .content {
                            .checkmark {
                                margin: 17px 0;
                            }
                        }
                    }

                    .get-in-btn {
                        button {
                            width: 31%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {

        // .home-img {
        //     display: none;
        // }

        .home-link-list {
            .justify-content-center {
                justify-content: flex-start !important;

                .fixed-tab {
                    margin: -11px;

                    ul {
                        justify-content: flex-start;
                        overflow: auto;
                    }
                    h2 {
                        padding: 0px 0 35px;
                        line-height: 19px;
                    }
                }

                form {
                    margin: 0 !important;

                    .form-group span {
                        right: 9% !important;
                    }
                    .form-group {
                        .text-center {
                            text-align: left !important;
                        }
                        .cvc-text {
                            width: 90%;
                        }
                        .quation-img {
                            position: absolute;
                            right: 11px;
                            margin: -62px 0;
                        }
                        .material-icons {
                            width: 89%;
                            margin-left: 38px;
                        }
                        .input-icon {
                            left: 50px;
                        }
                        .content {
                            .checkmark {
                                margin: 17px 0;
                            }
                        }
                    }

                    .get-in-btn {
                        button {
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}