.main-wrapper {
    .upload-block {
        text-align: center;
        padding: 0 0 3rem 0;

        .text {
            font-size: 34px;
            line-height: 50px;
            font-weight: 700;
        }

        .upload-icon {
            img {
                padding: 35px 0;
            }
        }

        .upload-img {
            margin-bottom: 59px;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin-right: 39px;

                img {
                    width: 153px;
                }

                &:last-child {
                    margin-right: 0;
                }

                &.active {
                    border: 8px solid #D62828;
                    border-radius: 96px;
                    padding: 10px;
                    background-color: #fff;
                }

                &:hover {
                    border: 8px solid #D62828;
                    border-radius: 96px;
                    padding: 10px;
                    background-color: #fff;
                    transform: scale(1.1);
                    transition: all .9s;
                }
            }
        }

        .acttion-btn {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                color: #D62828;
                font-weight: 600;
                background-color: #fff;
                border: 2px solid #D62828;
                border-radius: 50px;
                width: 433px;
                font-size: 16px;
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                &:hover {
                    background-color: #D62828;
                    color: #fff;
                }
            }

        }
    }

    .upload-block-mobile {
        display: none;
    }

    .home-activity-section {
        h5 {
            font-size: 15px;
            color: #959595;
            margin-bottom: 1rem;
        }

        .home-activity-banner {
            width: 100%;
            position: relative;
            bottom: 0;

            img {
                width: 100%;
                height: 376px;
            }

            .back-shadow-top {
                position: absolute;
                top: -7.3rem;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            .back-shadow-bottom {
                position: absolute;
                top: 7.5rem;
                left: 0;
                right: 0;
                margin: 0 auto;
            }

            .social-icon {
                position: absolute;
                display: flex;
                justify-content: end;
                top: 17px;
                left: 0;
                right: 14px;

                img {
                    width: 30px;
                    height: 30px;
                    background-color: #f5f5f5;
                    border-radius: 50px;
                    border: 3px solid #f5f5f5;
                }
            }

            .new-btn {
                position: absolute;
                font-size: 12px;
                top: 15px;
                font-weight: 400;
                background-color: #D62828;
                border-radius: 50px;
                color: #fff;
                left: 18px;
                width: 120px;
                height: 35px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .slick-dots {
            bottom: 27px;

            li {
                margin: 0 3px;
                width: 17px;
                height: 17px;

                button {
                    background: 0 0;

                    &:before {
                        width: 14px;
                        height: 14px;
                        // content: '•';
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 100%;
                        border-radius: 50px;
                        border: 3px solid #ffdf;
                        color: #18141300;
                    }
                }
            }

            li.slick-active button:before {
                opacity: 100%;
                color: #fff;
                background-color: #fff;
                width: 14px;
                height: 14px;
            }
        }
    }

    .detail-box-left {
        padding: 38px 0;

        .detail-box-text {
            h2 {
                color: #D62828;
                font-size: 43px;
                font-weight: 700;
                display: flex;
                margin: 0;
                padding: 1rem 0;
                align-items: flex-end;
                justify-content: flex-start;

                i {
                    display: flex;
                    font-style: inherit;

                    img {
                        margin-right: 13px;
                    }
                }
            }

            span {
                color: #003049;
                text-align: left;
                font-size: 28px;
                font-weight: 500;
                margin: 1rem 0;
            }
        }

        .order-main {
            display: flex;
            margin: 1rem 0;
            align-items: center;

            a {
                color: #959595;
                text-decoration: none;
                font-size: 16px;
                margin-right: 1rem;
                font-weight: 400;
                display: flex;

                img {
                    width: 15px;
                    margin-right: 8px;
                }
            }
        }

        .card-list {
            display: flex;
            justify-content: space-around;
            font-size: 44px;
            line-height: 36px;
            color: #959595;
            font-weight: 500;
            margin: 1rem 0 0;

            span {
                text-decoration: line-through;
            }

            strong {
                color: #D62828;
                font-weight: 700;
                margin: 0 5px;
            }

            a {
                color: #fff;
                font-weight: 400;
                background-color: #D62828;
                border-radius: 50px;
                width: 140px;
                text-decoration: none;
                font-size: 16px;
                height: 40px;
                display: flex;
                margin: 0 5px;
                align-items: center;
                justify-content: center;

                img {
                    width: 15px;
                    margin-right: 8px;
                }
            }

            p {
                font-size: 16px;
                margin: 0 5px;
            }
        }

        .block-text {
            background-color: #F8F8F8;
            padding: 1rem 24px;
            margin: 2.5rem 0 0 0;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 25px;

            ul {
                padding: 0;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                li {
                    display: inline-block;
                    margin-right: 1rem;

                    a {
                        color: #D62828;
                        font-weight: 400;
                        border: 2px solid #D62828;
                        border-radius: 50px;
                        width: 155px;
                        background-color: #fff;
                        text-decoration: none;
                        font-size: 16px;
                        height: 50px;
                        box-shadow: 0px 3px 6px #00000029;
                        height: 50px;
                        display: inline-block;
                        text-align: center;
                        line-height: 43px;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }

                        &.active {
                            background-color: #D62828;
                            color: #fff;
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            p {
                margin: 18px 0 18px 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #444444;
            }
        }
    }

    .detail-box-right {
        padding: 38px 0;
        position: relative;
        .detail-box-img {
            img {
                width: 100%;
                height: 540px;
                background-color: #D62828;
                border-radius: 22px;
                box-shadow: 0px 3px 6px #00000029;
            }
        }

        .detail-box-right-text {
            position: absolute;
            top: 0;
            padding: 60px;

            ul {
                padding: 0;
                margin: 0;

                li {
                    border-bottom: 1px solid #fff;
                    color: #fff;
                    padding: 24px 0;
                    list-style: none;

                    .card-list {
                        margin-top: 20px;
                        display: flex;
                        text-align: center;
                        align-items: baseline;
                        justify-content: space-between;

                        span {
                            text-decoration: line-through;
                            font-size: 24px;
                            font-weight: 600;
                        }

                        strong {
                            font-size: 24px;
                            font-weight: 700;
                        }

                        a {
                            color: #D62828;
                            font-weight: 400;
                            background-color: #fff;
                            border-radius: 50px;
                            width: 93px;
                            text-decoration: none;
                            font-size: 14px;
                            height: 30px;
                            display: flex;
                            margin: 0 5px;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    .content {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        text-align: left;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 14px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }
                        .checkmark {
                            position: absolute;
                            top: 5px;
                            left: 0;
                            height: 23px;
                            border: 2px solid #fff;
                            width: 23px;
                            border-radius: 50px;
                            background-color: #D62828;
                        }
                        input:checked ~ .checkmark {
                            background-color: #D62828;
                        }
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                        input:checked ~ .checkmark:after {
                            display: block;
                        }
                        .checkmark:after {
                            left: 3px;
                            top: 3px;
                            width: 13px;
                            height: 13px;
                            background-color: #fff;
                           border-radius: 50px;
                        }                 
                    }  
                }
            }

            .deals-expires {
                padding: 21px 0 0;
                text-align: center;
                display: flex;
                align-items: baseline;
                justify-content: center;

                img {
                    margin-right: 5px;
                }

                p {
                    color: #fff;
                }
            }

            .primary-btn {
                color: #fff;
                font-weight: 600;
                background-color: #FCBF49;
                border-radius: 50px;
                box-shadow: 0 3px 6px #00000029;
                width: 74%;
                border: 0;
                margin: 0 auto;
                font-size: 16px;
                margin-bottom: 0.5rem;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .deals-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 13px 0;

                a {
                    color: #fff;
                    font-weight: 400;
                    border: 2px solid #fff;
                    border-radius: 50px;
                    width: 145px;
                    text-decoration: none;
                    font-size: 16px;
                    height: 40px;
                    margin-right: 10px;
                    box-shadow: 0px 3px 6px #00000029;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    img {
                        margin-right: 5px;
                    }
                }

                ul {
                    img {
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .deals-bought {
        background-image: url(../images/one-banner2.png);
        background-color: #D62828;
        width: 100%;
        height: 845px;
        position: relative;
        overflow: hidden;

        .deals-bought-text {
            position: absolute;
            top: 20px;
            text-align: center;
            left: 0;
            padding: 35px 0;
            right: 0;

            h2 {
                font-size: 34px;
                font-weight: bold;
                color: #fff;

            }

            p {
                color: #fff;
            }
        }

        .deals-bought-box {
            position: relative;
            top: 12rem;
            left: 0;
            background-color: #fff;
            border-radius: 27px;
            margin-bottom: 2rem;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
            right: 0;

            .home-block-img {
                img {
                    width: 100%;
                }

                .black-shadow {
                    left: 0px;
                    position: absolute;
                }

                .social-icon {
                    position: absolute;
                    top: 13px;
                    display: flex;
                    justify-content: end;
                    left: 88%;

                    img {
                        width: 30px;
                        height: 30px;
                        background-color: #f5f5f5;
                        border-radius: 50px;
                        border: 3px solid #f5f5f5;
                    }
                }

                .new-btn {
                    position: absolute;
                    font-size: 12px;
                    font-weight: 400;
                    background-color: #D62828;
                    border-radius: 50px;
                    color: #fff;
                    left: 11px;
                    width: 120px;
                    top: 13px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .yellow-light {
                    background-color: #FCBF49;
                }

                .yellow {
                    background-color: #F77F00;
                }
            }

            .home-text {
                padding: 13px 13px;
                margin-bottom: 2rem;
                position: relative;
                top: 0px;

                h4 {
                    color: #D62828;
                    font-size: 20px;
                    font-weight: 700;
                    display: flex;
                    margin: 0.5rem 0;
                    align-items: flex-end;
                    justify-content: flex-start;

                    i {
                        display: block;
                        font-style: inherit;

                        img {
                            margin-right: 13px;
                        }

                        b {
                            color: #FCBF49;
                            font-weight: 700;
                        }

                        span {
                            color: #F77F00;
                            font-weight: 700;
                        }
                    }
                }

                .yellow-light {
                    color: #FCBF49;
                    font-weight: 700;
                }

                .yellow {
                    color: #F77F00;
                    font-weight: 700;
                }

                h5.grill-house {
                    color: #003049;
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 1rem 0;

                    a {
                        color: #959595;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }
                }

                p {
                    color: #959595;
                    font-size: 12px;
                    margin: 0rem 0;
                }

                .card-list {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 28px;
                    line-height: 36px;
                    color: #959595;
                    font-weight: 500;
                    margin: 1rem 0 0;

                    span {
                        text-decoration: line-through;
                    }

                    strong {
                        color: #be1622;
                        font-weight: 900;
                    }

                    .yellow-light {
                        color: #FCBF49;
                    }

                    .yellow {
                        color: #F77F00;
                    }

                    a {
                        color: #fff;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 120px;
                        text-decoration: none;
                        font-size: 16px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }
                }

                .yellow-light {
                    a {
                        background-color: #FCBF49;
                    }
                }

                .yellow {
                    a {
                        background-color: #F77F00;
                    }
                }

                .card-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                button {
                    color: #fff;
                    font-weight: 600;
                    background-color: #D62828;
                    border-radius: 50px;
                    width: 100%;
                    border: 0;
                    font-size: 16px;
                    margin-bottom: 0.5rem;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .acttion-block {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            position: relative;
            top: 14rem;

            button {
                color: #fff;
                font-weight: 600;
                background-color: #D62828;
                border: 2px solid #fff;
                border-radius: 50px;
                width: 150px;
                font-size: 16px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                &:hover {
                    background-color: #fff;
                    color: #D62828;
                }
            }
        }
    }

    .deals-bought-mobile {
        display: none;
    }
}

#deals-details {
    padding: 3rem 0 0;
}


@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .upload-block {
            .upload-img {
                li {
                    margin-right: 24px;
                    img {
                        width: 150px;
                    }
                }
            }
        }
        .home-activity-section {
            .home-activity-banner {
                .back-shadow-top {
                    top: -127px;
                }
                .back-shadow-bottom {
                    top: 129px;
                }
            }
        }
        .detail-box-left {
            .detail-box-text {
                h2 {
                    font-size: 35px;
                    // i {
                    //     img {
                    //         width: 36px;
                    //     }
                    // }
                }
            }
            .card-list {
                font-size: 30px;
            }
            .block-text {
                ul {
                    li {
                        margin-right: 5px;
                        a {
                            width: 140px;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .detail-box-right {
            .detail-box-right-text {
                padding: 60px 20px;
            }
            .detail-box-img {
                img {
                    height: 540px;
                }
            }
        }
        .deals-bought {
            .deals-bought-box {
                .home-block-img {
                    img {
                        width: 296px;
                    }
                }
                .home-text {
                    h4 {
                        font-size: 17px;
                        i {
                            display: block;
                        }
                    }
                    h5.grill-house {
                        font-size: 15px;
                        align-items: center;
                    }
                    .card-list {
                        font-size: 21px;
                        a {
                            width: 106px;
                            font-size: 14px;
                            height: 35px;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    .main-wrapper {
        .upload-block {
            .upload-img {
                li {
                    margin-right: 17px;
                    img {
                        width: 100px;
                    }
                }
            }
        }
        .home-activity-section {
            .home-activity-banner {
                position: relative;
                overflow: hidden;
                width: 100%;
                .back-shadow-top {
                    top: -140px;
                }
                .back-shadow-bottom {
                    top: 143px;
                }
            }
           
        }
        .detail-box-right {
            .detail-box-img {
                img {
                    height: 600px;
                }
            }
        }
    }
}



@media only screen and (max-width: 767px) {
    .main-wrapper {
        .upload-block {
            padding: 0;

            .text {
                font-size: 29px;
                line-height: 35px;
            }

            .upload-icon {
                img {
                    width: 166px;
                }
            }

            .upload-img {
                margin-bottom: 59px;
                padding: 0;
                display: none;
                list-style: none;
            }

            .acttion-btn {
                text-align: center;
                display: flex;
                display: none;
                align-items: center;
                justify-content: center;
                width: 100%;

                button {
                    color: #D62828;
                    font-weight: 600;
                    background-color: #fff;
                    border: 2px solid #D62828;
                    border-radius: 50px;
                    width: 100%;
                    font-size: 16px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                    &:hover {
                        background-color: #D62828;
                        color: #fff;
                    }
                }

            }
        }

        .deals-bought {
            display: none;
        }

        .deals-bought-mobile {
            display: block;
            background-image: url(../images/one-banner2.png);
            background-color: #D62828;
            width: 100%;
            margin: -10px 0;

            .slick-slider {
                padding: 0;
                .slick-arrow {
                    display: none !important;
                }
                .slick-next {
                    right: 0 !important;
                }
            }

            .slick-dots {
                display: none !important;
            }

            .deals-bought-text {
                position: relative;
                top: 0rem;
                text-align: center;
                left: 0;
                padding: 50px 0 35px;
                right: 0;

                h2 {
                    font-size: 24px;
                    font-weight: bold;
                    color: #fff;

                }

                p {
                    color: #fff;
                }
            }

            .deals-bought-box {
                position: relative;
                top: 0rem;
                margin-right: 10px;
                margin-left: 10px;
                left: 0;
                background-color: #fff;
                border-radius: 27px;
                margin-bottom: 2rem;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                right: 0;

                .home-block-img {
                    img {
                        width: 100%;
                    }

                    .black-shadow {
                        left: 0px;
                        position: absolute;
                        top: 0;
                    }

                    .social-icon {
                        position: absolute;
                        top: 13px;
                        display: flex;
                        justify-content: end;
                        right: 14px;

                        img {
                            width: 30px;
                            height: 30px;
                            background-color: #f5f5f5;
                            border-radius: 50px;
                            border: 3px solid #f5f5f5;
                        }
                    }

                    .new-btn {
                        position: absolute;
                        font-size: 12px;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        color: #fff;
                        left: 11px;
                        width: 120px;
                        top: 13px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .yellow-light {
                        background-color: #FCBF49;
                    }

                    .yellow {
                        background-color: #F77F00;
                    }
                }

                .home-text {
                    padding: 13px 13px;
                    margin-bottom: 2rem;
                    position: relative;
                    top: 0px;

                    h4 {
                        color: #D62828;
                        font-size: 18px;
                        font-weight: 700;
                        display: flex;
                        margin: 0.5rem 0;
                        align-items: flex-end;
                        justify-content: flex-start;

                        i {
                            display: flex;
                            font-style: inherit;

                            img {
                                margin-right: 13px;
                            }

                            b {
                                color: #FCBF49;
                                font-weight: 700;
                            }

                            span {
                                color: #F77F00;
                                font-weight: 700;
                            }
                        }
                    }

                    .yellow-light {
                        color: #FCBF49;
                        font-weight: 700;
                    }

                    .yellow {
                        color: #F77F00;
                        font-weight: 700;

                    }

                    h5.grill-house {
                        color: #003049;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 17px;
                        font-weight: 500;
                        margin: 1rem 0;

                        a {
                            color: #959595;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: 400;
                            display: flex;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }
                    }

                    p {
                        color: #959595;
                        font-size: 12px;
                        margin: 0rem 0;
                    }

                    .card-list {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        font-size: 24px;
                        line-height: 36px;
                        color: #959595;
                        font-weight: 500;
                        margin: 1rem 0 0;

                        span {
                            text-decoration: line-through;
                        }

                        strong {
                            color: #be1622;
                            font-weight: 900;
                        }

                        .yellow-light {
                            color: #FCBF49;
                        }

                        .yellow {
                            color: #F77F00;
                        }

                        a {
                            color: #fff;
                            font-weight: 400;
                            background-color: #D62828;
                            border-radius: 50px;
                            width: 110px;
                            text-decoration: none;
                            font-size: 15px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }
                    }

                    .yellow-light {
                        a {
                            background-color: #FCBF49;
                        }
                    }

                    .yellow {
                        a {
                            background-color: #F77F00;
                        }
                    }

                    .card-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }

            .acttion-block {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                position: relative;
                top: 0rem;

                button {
                    color: #fff;
                    font-weight: 600;
                    background-color: #D62828;
                    border: 2px solid #fff;
                    border-radius: 50px;
                    width: 100%;
                    font-size: 16px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                    &:hover {
                        background-color: #fff;
                        color: #D62828;
                    }
                }
            }
        }

        .detail-box-left {
            padding: 8px 0;
            margin: -12px;
            .block-text {
                border-radius: 0;
                position: relative;
                z-index: 1;
                top: 33.3rem;
                padding: 1rem 0;
                p {
                    padding: 0 24px;
                }
            }
            .card-list {
                font-size: 22px;
                padding: 0px 10px;
                margin: 0;

                a {
                    width: 100px;
                    font-size: 13px;
                    height: 30px;
                }

                p {
                    font-size: 12px;
                }
            }

            .block-text ul {
                padding: 10px 10px 10px 10px;
                overflow: auto;
                justify-content: left;
                &::-webkit-scrollbar {
                    display:none;
                }
            }

            .detail-box-text {
                padding: 10px;
                h2 {
                    font-size: 22px;

                    img {
                        width: 22px;
                    }
                }

                span {
                    font-size: 16px;
                }
            }
            .order-main {
                padding: 10px;
                margin: 0;
            }
        }

        .detail-box-right {
            padding: 0;
            margin: 0 -12px;
            position: relative;
            bottom: 0;
            top: -38rem;
            .detail-box-img {
                img {
                    height: 540px;
                    border-radius: 0;
                }
            }

            .detail-box-right-text {
                padding: 0px 10px;
                ul {

                    li {
                        font-size: 12px;
                        padding: 24px 0 24px 0px;
                        .card-list {
                            margin-top: 20px;
                            display: flex;
                            text-align: center;
                            align-items: baseline;
                            justify-content: space-between;

                            span {
                                font-size: 20px;
                            }

                            strong {
                                font-size: 20px;
                            }

                            a {
                                color: #D62828;
                                font-weight: 400;
                                background-color: #fff;
                                border-radius: 50px;
                                width: 93px;
                                text-decoration: none;
                                font-size: 14px;
                                height: 30px;
                                display: flex;
                                margin: 0 5px;
                                align-items: center;
                                justify-content: center;
                            }
                        }

                        &:before {
                            left: 9px;
                            margin: 3px 0;
                        }
                        &:after {
                            margin: -116px 0;
                            left: 12px;
                            font-size: 36px;
                        }
                    }
                }

                .deals-expires {
                    p {
                        font-size: 14px;
                    }
                }

                .primary-btn {
                    color: #fff;
                    font-weight: 600;
                    background-color: #FCBF49;
                    border-radius: 50px;
                    box-shadow: 0 3px 6px #00000029;
                    width: 100%;
                    border: 0;
                    font-size: 16px;
                    margin-bottom: 0.5rem;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .deals-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 13px 0;

                    a {
                        color: #fff;
                        font-weight: 400;
                        border: 2px solid #fff;
                        border-radius: 50px;
                        width: 145px;
                        text-decoration: none;
                        font-size: 16px;
                        height: 40px;
                        margin-right: 10px;
                        box-shadow: 0px 3px 6px #00000029;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: center;

                        img {
                            margin-right: 5px;
                        }
                    }

                    ul {
                        img {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .upload-block-mobile {
            display: block;
            text-align: center;
            padding: 0 0 3rem 0;

            .upload-img {
                margin-bottom: 32px;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 16px;

                    img {
                        width: 93px;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &.active {
                        border: 4px solid #D62828;
                        border-radius: 89px;
                        padding: 5px;
                        background-color: #fff;
                    }

                    &:hover {
                        border: 4px solid #D62828;
                        border-radius: 89px;
                        padding: 5px;
                        background-color: #fff;
                        transform: scale(1.1);
                        transition: all .9s;
                    }
                }
            }

            .acttion-btn {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                button {
                    color: #D62828;
                    font-weight: 600;
                    background-color: #fff;
                    border: 2px solid #D62828;
                    border-radius: 50px;
                    width: 100%;
                    font-size: 16px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                    &:hover {
                        background-color: #D62828;
                        color: #fff;
                    }
                }

            }
        }

        .home-activity-section {
            .slick-slider {
                margin: -11px;
                .slick-arrow {
                    display: none !important;
                }
                .slick-next {
                    right: 0 !important;
                }
            }
            h5 {
                display: none;
            }
            .home-activity-banner {
                .back-shadow-top {
                    top: -1rem;
                    height: 100px;
                }
                .back-shadow-bottom {
                    top: 10rem;
                }
                .social-icon {
                    top: 31px;
                }
                .new-btn {
                    top: 31px;
                }
            }
        }
    }
    #deals-details {
        padding: 0;
    }
}