.main-wrapper {
    display: block;
}

#awesome-deal-page {
    padding: 0px 0px;

    .bg-image {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: 145px;

        img {
            width: 100%;
        }

        .box-shadow {
            position: absolute;
            top: 5px;
            left: 0;
            margin: -16px;
            right: 0;
            z-index: -1;
            width: 103%;
            display: block;
        }

        ul.react-tabs__tab-list {
            position: absolute;
            top: 0px;
            display: flex;
            list-style: none;
            left: 0;
            flex-wrap: nowrap;
            justify-content: center;
            align-items: center;
            padding: 2.7rem 0;

            li.react-tabs__tab {
                cursor: pointer;
                align-items: center;
                display: flex;
                font-size: 21px;
                font-weight: 600;
                height: 60px;
                padding: 0 1rem 0 0;
                margin-right: -29px;
                position: relative;
                width: 188px;
                z-index: 9;
                justify-content: center;

                &:first-child {
                    border-bottom-left-radius: 50px;
                    border-top-left-radius: 50px;
                    clip-path: polygon(75% 0, 91% 50%, 75% 100%, 0 100%, 0 50%, 0 0);
                    color: #fff;
                    background-color: #fff;
                    z-index: 9;
                    box-shadow: 0px 3px 10px #00000029;

                    &::after {
                        background-color: #D62828;
                        border-bottom-left-radius: 50px;
                        border-top-left-radius: 50px;
                        clip-path: polygon(75% 0, 90% 50%, 75% 100%, 0 100%, 0% 50%, 0 0);
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &.active {
                            background-color: #F77F00;
                            width: 166px;
                            height: 55px;
                            border-radius: 50px 0px 0px 50px;
                            display: flex;
                            z-index: 1;
                            overflow: hidden;
                            clip-path: polygon(0% 0%, 82% 0%, 99% 50%, 82% 100%, -2% 100%);
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                &:nth-child(2) {
                    border-bottom-left-radius: 5px;
                    -webkit-border-top-left-radius: 5px;
                    border-top-left-radius: 5px;
                    clip-path: polygon(75% 0, 90% 50%, 75% 100%, 0 100%, 14% 50%, 0 0);
                    color: #fff;
                    background-color: #fff;
                    box-shadow: 0px 3px 10px #00000029;

                    &::after {
                        background-color: #D62828;
                        clip-path: polygon(75% 0%, 89% 50%, 75% 100%, 1% 100%, 15% 50%, 1% 0);
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &.active {
                            background-color: #F77F00;
                            width: 163px;
                            height: 57px;
                            display: flex;
                            z-index: 1;
                            overflow: hidden;
                            clip-path: polygon(83% 0%, 99% 50%, 84% 98%, 0% 100%, 16% 50%, 0% 0%);
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                &:nth-child(3) {
                    border-bottom-right-radius: 50px;
                    border-top-right-radius: 50px;
                    clip-path: polygon(0% 0%, 100% 0%, 108% 100%, 0% 100%, 14% 50%);
                    color: #fff;
                    padding-left: 1px;
                    padding-right: 0px;
                    background-color: #fff;
                    box-shadow: 0px 3px 10px #00000029;

                    &::after {
                        background-color: #D62828;
                        border-bottom-right-radius: 50px;
                        border-top-right-radius: 50px;
                        clip-path: polygon(1% 0%, 100% 0%, 100% 100%, 1% 100%, 15% 50%);
                    }

                    a {
                        color: #fff;
                        text-decoration: none;
                        overflow: hidden;
                        position: relative;

                        &.active {
                            background-color: #F77F00;
                            width: 180px;
                            height: 55px;
                            border-radius: 0px 50px 50px 0px;
                            display: flex;
                            z-index: 1;
                            overflow: hidden;
                            clip-path: polygon(100% 0%, 100% 38%, 112% 150%, -5% 117%, 15% 50%, 0% 0%);
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }

                &::after {
                    bottom: 0;
                    -webkit-clip-path: polygon(75% 0, 85% 50%, 75% 100%, 0 100%, 10% 50%, 0 0);
                    clip-path: polygon(1% 0%, 100% 0%, 100% 100%, 2% 100%, 15% 50%);
                    content: "";
                    height: 93%;
                    left: 3px;
                    position: absolute;
                    top: 2px;
                    transform: translate(0);
                    width: 97%;
                    z-index: -1;
                }
            }

        }
    }

    .awesome-page {
        background-color: #F5F5F5;
        padding: 50px 0px;

        .awesome-box {
            display: flex;
            margin: 0 auto;
            width: 840px;

            .awesome-img {
                position: relative;
                overflow: hidden;
                width: 100%;

                img {
                    width: 100%;
                }

                .black-shadow {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                }

                .social-icon {
                    position: absolute;
                    top: 15px;
                    left: 87%;
                    display: flex;
                    justify-content: end;

                    img {
                        width: 30px;
                        height: 30px;
                        background-color: #f5f5f5;
                        border-radius: 50px;
                        border: 3px solid #f5f5f5;
                    }
                }

                .new-btn {
                    position: absolute;
                    font-size: 12px;
                    top: 0;
                    font-weight: 400;
                    background-color: #D62828;
                    border-radius: 50px;
                    color: #fff;
                    left: 0px;
                    margin: 1rem 0.5rem;
                    width: 120px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .awesome-text {
                background-color: #fff;
                padding: 13px 13px;
                border-bottom-right-radius: 30px;
                border-top-right-radius: 30px;

                h4 {
                    color: #D62828;
                    font-size: 20px;
                    font-weight: bold;
                    display: flex;
                    margin: 0.5rem 0;
                    align-items: flex-end;
                    justify-content: flex-start;

                    i {
                        font-style: inherit;

                        img {
                            margin-right: 13px;
                            width: 20px;
                        }
                    }
                }

                h5.grill-house {
                    color: #003049;
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 1rem 0;

                    a {
                        color: #959595;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }
                }

                p {
                    color: #959595;
                    font-size: 12px;
                    margin: 1rem 0;
                    text-align: left;
                }

                .card-list {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 28px;
                    line-height: 36px;
                    color: #959595;
                    font-weight: 500;
                    margin: 1rem 0 0;

                    span {
                        text-decoration: line-through;
                    }

                    strong {
                        color: #be1622;
                        font-weight: 900;
                    }

                    .light-color {
                        color: #FCBF49;
                    }

                    .col-strong {
                        color: #F77F00;
                    }

                    a {
                        color: #fff;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 120px;
                        text-decoration: none;
                        font-size: 16px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }

                    .yellow-ligh {
                        background-color: #FCBF49;
                    }

                    .yellow-bg {
                        background-color: #F77F00;
                    }
                }

                .card-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                button {
                    color: #fff;
                    font-weight: 600;
                    background-color: #D62828;
                    border-radius: 50px;
                    width: 100%;
                    border: 0;
                    font-size: 16px;
                    margin-bottom: 0.5rem;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .ms-want-btn {
                    display: flex;
                    margin: 0;
                    padding: 20px 0;
                }
            }
        }

        form {
            .card-icon {
                .container {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    text-align: left;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 14px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    .checkmark {
                        position: absolute;
                        top: 1px;
                        left: 0;
                        height: 23px;
                        width: 23px;
                        border-radius: 5px;
                        background-color: #D62828;
                    }

                    input:checked~.checkmark {
                        background-color: #D62828;
                    }

                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    input:checked~.checkmark:after {
                        display: block;
                    }

                    .checkmark:after {
                        left: 9px;
                        top: 4px;
                        width: 7px;
                        height: 12px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .awesome-btn {
            padding: 50px 0 0;

            ul {
                margin: 0 0 0rem 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 10px;
                    position: relative;
                    overflow: hidden;

                    .primary-btn-sm {
                        color: #D62828;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #D62828;
                        border-radius: 50px;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-weight: 500;

                        &:hover {
                            background: #D62828;
                            color: #fff;
                        }

                        &.active {
                            background: #D62828;
                            color: #fff;
                        }
                    }

                    .back-to {
                        position: absolute;
                        top: 17px;
                        left: 22px;
                    }

                    .lets-btn {
                        position: absolute;
                        top: 17px;
                        right: 22px;
                    }

                    .primary-btn {
                        color: #F77F00;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #F77F00;
                        border-radius: 50px;
                        background-color: #fff;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-weight: 500;

                        &:hover {
                            color: #F77F00;
                        }

                        &.active {
                            background: #F77F00;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .text {
            p {
                font-size: 34px;
                padding: 25px 0;
            }

            a.order-btn {
                box-shadow: 0px 3px 6px #00000029;
                width: 150px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                color: #585858;
                font-size: 18px;
                line-height: 25px;
                border: 0.5px solid #959595;
                border-radius: 50px;
                text-decoration: none;
                margin: 0 auto;
            }

            h4 {
                font-size: 18px;
                margin: -22px 0;
            }

            span {
                font-size: 34px;
                line-height: 47px;
                color: #444444;
                font-weight: 500;
                position: relative;
                top: 15px;
            }
        }

        .payments-conform {
            padding-top: 50px;

            a {
                text-decoration: none;
                color: #D62828;
                font-size: 45px;
                font-weight: bold;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: center;

                img {
                    margin-right: 5px;
                    width: 47px;
                }
            }

            h5 {
                font-size: 34px;
                color: #003049;
                line-height: 50px;
                padding: 20px 0 0;
                font-weight: bold;
                margin: 0 auto;
            }

            ul {
                margin: 17px 0 0;
                padding: 0;
                list-style: none;
                display: flex;
                justify-content: center;

                li {
                    display: inline-block;
                    margin-right: 10px;

                    a.primary-btn-sm {
                        color: #fff;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #D62828;
                        border-radius: 50px;
                        background-color: #D62828;
                        width: 100%;
                        padding: 0 21px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-weight: 500;
                    }

                    img {
                        margin-right: 5px;
                        width: 20px;
                    }

                    a.primary-btn {
                        color: #F77F00;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #F77F00;
                        border-radius: 50px;
                        background-color: #fff;
                        width: 100%;
                        padding: 0 21px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-weight: 500;
                    }
                }
            }
        }

        .date-time-seation {
            padding-top: 20px;

            h2 {
                font-size: 34px;
                color: #003049;
                line-height: 50px;
                padding: 8px 0 0;
                font-weight: bold;
                margin: 0 auto;
            }

            .date-inner {
                list-style: none;
                padding: 30px 37px 30px;
                margin: 15px auto 0;
                width: 80%;
                background-color: #fff;
                border: 1px solid #959595;
                border-radius: 25px;

                .ratei-img {
                    display: grid;
                    align-items: center;
                    justify-content: center;
                    img {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 20px;
                        margin-right: 0px;
                        background-color: #F5F5F5;
                        border: 0.25px solid #959595;
                        border-radius: 50px;
                        width: 93px;
                        height: 93px;
                   }
                   p.red-color {
                      color: #D62828;
                      font-weight: 500;
                   }
                }

                p {
                    font-size: 15px;
                    font-style: normal;
                    text-align: center;
                }
            }
        }

        .payments-text {
            h2 {
                color: #D62828;
                font-size: 60px;
                margin-top: 13px;
                line-height: 69px;
                margin-bottom: 50px;
                font-weight: bold;
            }

            .quation-img {
                margin: 10px 0;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    #awesome-deal-page {
        .awesome-page {
            .awesome-box {
                .awesome-img {
                    .new-btn {
                        margin: 0rem;
                        top: 20px;
                        left: 20px;
                    }

                    .social-icon {
                        right: 20px;
                        top: 20px;
                    }
                }

                .awesome-text {
                    width: 100%;
                    border-radius: 0 30px 30px 0px;
                }
            }
        }

        .bg-image {
            img {
                height: 176px;
            }

            // ul.react-tabs__tab-list {
            //     top: -111px;
            // }
        }
    }
}


@media only screen and (max-width: 991px) {
    #awesome-deal-page {
        .awesome-page {
            .awesome-box {
                display: block;
                width: 100%;

                .awesome-img {
                    img {
                        border-radius: 0 43px 0px 5px;
                    }

                    .new-btn {
                        margin: 0rem;
                        top: 20px;
                        left: 20px;
                    }

                    .social-icon {
                        right: 20px;
                        top: 20px;
                    }
                }

                .awesome-text {
                    width: 100%;
                    border-radius: 0 0 30px 30px;
                }
            }

            .payments-text {
                form {
                    .form-group {
                        .text-center {
                            text-align: left !important;
                        }

                        .cvc-text {
                            width: 92%;
                            display: flex;
                        }

                        .quation-img {
                            position: absolute;
                            right: 117px;
                            margin: -69px 0;
                        }
                    }
                }
            }
        }

        .bg-image {
            img {
                height: 176px;
            }

            // ul.react-tabs__tab-list {
            //     top: -111px;
            // }
        }
    }
}


@media only screen and (max-width: 767px) {
    #awesome-deal-page {
        padding: 0px 0px;

        .bg-image {
            height: 77px;

            img {
                width: 100%;
                height: 118px;
            }

            .box-shadow {
                width: 116%;
                top: 0;
                margin: -17px 0 0 -30px;
            }

            ul.react-tabs__tab-list {
                padding: 0;
                margin: 20px 0;

                li.react-tabs__tab {
                    font-size: 12px;
                    height: 36px;
                    padding: 0;
                    width: 118px;
                    margin-right: -15px;

                    &:first-child {
                        clip-path: polygon(77% 0, 92% 52%, 77% 100%, 0 100%, 0 50%, 0 0);

                        &::after {
                            clip-path: polygon(75% 0, 90% 50%, 75% 96%, 0 92%, 0% 50%, 0 0);
                        }

                        a {
                            &.active {
                                width: 114px;
                                height: 32px;
                                clip-path: polygon(0% 0%, 77% 0%, 91% 52%, 77% 100%, 0% 100%);
                            }
                        }
                    }

                    &:nth-child(2) {
                        clip-path: polygon(77% 2%, 92% 50%, 77% 100%, 1% 100%, 14% 50%, 0% -3%);

                        &::after {
                            clip-path: polygon(75% 0, 90% 50%, 75% 96%, 1% 96%, 14% 50%, 1% 0);
                        }

                        a {
                            &.active {
                                width: 109px;
                                height: 32px;
                                clip-path: polygon(78% 0%, 93% 52%, 78% 100%, -1% 100%, 13% 50%, -1% 1%);
                            }
                        }
                    }

                    &:nth-child(3) {
                        clip-path: polygon(-1% -4%, 100% 0%, 100% 100%, 1% 100%, 15% 50%);

                        &::after {
                            width: 112px;
                            clip-path: polygon(106% 0%, 100% 100%, 100% 95%, 2% 95%, 15% 50%, 2% 0);
                        }

                        a {
                            &.active {
                                width: 112px;
                                height: 32px;
                                clip-path: polygon(100% 0%, 100% 4%, 106% 100%, -5% 119%, 14% 50%, 0% 0%);
                            }
                        }
                    }
                }

            }
        }

        .awesome-page {
            padding: 50px 0px 20px;

            .awesome-box {
                display: block;
                width: 100%;
                border-radius: 25px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .awesome-img {
                    img {
                        border-radius: 0;
                    }

                    .social-icon {
                        right: 13px;
                        top: 13px;
                    }

                    .new-btn {
                        top: 13px;
                        left: 13px;
                    }
                }

                .awesome-text {
                    width: 100%;
                    border-radius: 0 0 25px 25px;
                }
            }
            .date-time-seation {
                .date-time-active {
                    border-collapse: collapse;
                    overflow: scroll;
                    margin: 0 -11px;
                    padding: 0 10px;
                    &::-webkit-scrollbar {
                        display:none;
                    }
                }
                .date-inner {
                    width: 800px;
                    padding: 20px 20px;
                    .ratei-img {
                        img {
                            width: 85px;
                            height: 85px;
                        }
                        p {
                            margin-bottom: 0;
                            font-size: 14px;
                            .red-color {
                                color: #D62828;
                                font-weight: 500;
                            }
                        }
                    }
                    .date-section {
                        display: flex;
                        z-index: 0;
                        position: relative;
                        overflow: hidden;
                        justify-content: center;
                        img {
                            padding: 20px;
                            margin-right: 10px;
                            background-color: #F5F5F5;
                            border: 0.25px solid #959595;
                            border-radius: 50px;
                            width: 83px;
                            height: 83px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }

            .text {
                h4 {
                    margin: -9px 0;
                }
            }

            .payments-conform {
                padding-top: 25px;
            }

            .date-time-seation {
                ul {
                    width: 100%;
                    justify-content: flex-start;

                    li {
                        min-width: 100px;
                        height: 100px;
                        border-radius: 100px;

                        img {
                            width: 50px;
                        }
                    }
                }
            }

            .payments-text {
                form {
                    .text-center {
                        text-align: left !important;
                    }

                    .form-group {
                        .cvc-text {
                            width: 90%;
                            display: flex;
                        }

                        .quation-img {
                            position: absolute;
                            right: 12px;
                            margin: -70px 0;
                        }
                    }
                }
            }

            .ms-want-btn {
                margin: 0;
                padding: 3rem 0 0;
            }

            .awesome-btn {
                padding: 32px 0 0px;

                ul {
                    li {
                        display: block;
                        margin-bottom: 18px;

                        .primary-btn-sm {
                            width: 100%;
                        }

                        .primary-btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}