.main-wrapper {
    display: block;
    .varification-banner {
        padding: 3rem 0 14rem;
        text-align: center;
        background-color: #F5F5F5;
        .varification-img {
            margin-bottom: 25px;
        }
        h2 {
            font-size: 32px;
            line-height: 45px;
            font-weight: 700;
            color: #003049;
            margin: 0 auto;
            width: 66%;
        }
        .varification-no-code {
            ul {
                padding: 3rem 0 15px 0;
                margin: 0;
                list-style: none;
                li {
                    display: inline-block;
                    margin-right: 5px;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        border: 2px solid #003049;
                        width: 50px;
                        background-color: #fff;
                        height: 50px;
                        display: flex;
                        padding: 10px;
                        border-radius: 50px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .varification-banner {
            padding: 3rem 0 5rem;
        }
    }
}


@media only screen and (max-width: 991px) {
    .main-wrapper {
        .varification-banner {
            padding: 3rem 0 5rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {
        .varification-banner {
            padding: 2.3rem 0 1.5rem;
            h2 {
                width: 100%;
                font-size: 19px;
                line-height: 24px;
            }
            .varification-no-code {
                ul {
                    padding: 1rem 0 15px;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
}