.main-wrapper {
    .signup-banner {
        text-align: center;

        .my-sumo-button {
            p {
                font-size: 38px;
                color: #003049;
                line-height: 50px;
                padding: 42px 0;
                font-weight: bold;
                width: 61%;
                margin: 0 auto;
            }

            ul {
                margin: 0 0 3rem 0;
                padding: 0;
                list-style: none;

                li {
                    .primary-btn-sm {
                        &:hover {
                            background: #D62828;
                            color: #fff;
                        }

                        &.active {
                            background: #D62828;
                            color: #fff;
                        }
                    }
                }
            }
        }

        form {
            margin: 0 23rem;

            .form-group {
                input {
                    border: 0.5px solid #959595;
                    border-radius: 50px;
                    color: #959595;
                    font-size: 16px;
                    font-style: normal;
                    width: 100%;
                    height: 50px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    line-height: 32px;
                    margin-bottom: 2rem;
                    padding: 0 1.6rem;
                }

                input:-webkit-autofill {
                    transition: background-color 9999s ease-in-out 0s;
                    -webkit-text-fill-color: var(--text-black) !important;
                }

                input:focus-visible {
                    outline: 0;
                }

                span {
                    position: absolute;
                    margin: 13px 0;
                    right: 33%;
                }
            }

            h2 {
                font-size: 17px;
                color: #444444;
                font-weight: 400;

                a {
                    text-decoration: none;
                    color: #003049;
                    font-weight: 700;
                }
            }

            .form-check {
                padding: 0;

                p {
                    font-size: 16px;
                    margin: 1rem 0;

                    a {
                        font-weight: 600;
                        color: #444444;
                    }
                }

                .container {
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    text-align: left;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 14px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;

                    input {
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                        height: 0;
                        width: 0;
                    }

                    .checkmark {
                        position: absolute;
                        top: 5px;
                        left: 0;
                        height: 23px;
                        width: 23px;
                        border-radius: 5px;
                        background-color: #D62828;
                    }

                    input:checked~.checkmark {
                        background-color: #D62828;
                    }

                    .checkmark:after {
                        content: "";
                        position: absolute;
                        display: none;
                    }

                    input:checked~.checkmark:after {
                        display: block;
                    }

                    .checkmark:after {
                        left: 9px;
                        top: 4px;
                        width: 7px;
                        height: 12px;
                        border: solid white;
                        border-width: 0 3px 3px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
            }

            .get-in-btn {
                margin: 1.5rem 0;

                button {
                    color: #fff;
                    font-size: 15px;
                    text-decoration: none;
                    border: 2px solid #D62828;
                    border-radius: 50px;
                    width: 100%;
                    background-color: #D62828;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    font-weight: 500;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                    span {
                        margin-left: 10px;
                    }
                }
            }

            .get-in-btn {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #D62828;
                border-radius: 50px;
                width: 100%;
                background-color: #D62828;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                font-weight: 500;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
            }

            .button {
                font-size: 1em;
                padding: 10px;
                color: #fff;
                border-radius: 50px;
                text-decoration: none;
                cursor: pointer;
                transition: all 0.3s ease-out;
            }
        }
    }

    &#signup-page {
        padding: 50px 0;
    }
}

.share-popup-modal {
    .modal-dialog {
        width: 385px;

        .modal-content {
            border-radius: 25px;
            height: auto;

            .modal-header {
                border-bottom: 0;
                position: relative;

                button {
                    &.btn-close {
                        position: absolute;
                        top: 27px;
                        right: 33px;
                        padding: 0px;
                        /* margin: 0px; */
                        width: 20px;
                        height: 20px;
                        background-image: url(../images/close-btn.svg);
                        opacity: 100%;
                        background-size: 20px;

                        &:hover {
                            opacity: 100%;
                        }
                    }

                    &.btn-close:focus {
                        box-shadow: none;
                    }
                }
            }

            .modal-body {
                padding: 0px 15px 48px;

                .share-popup-modal-inner {
                    text-align: center;

                    .share-popup {
                        img {
                            width: 123px;
                        }

                        h2 {
                            margin-top: 0;
                            padding: 11px 0 0;
                            color: #c1272d;
                            font-size: 34px;
                            font-family: Co Headline Bold;
                            line-height: 40px;
                        }

                        p {
                            color: #002333;
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 33px;
                            margin-bottom: 0;
                            font-family: Lato-Regular;
                        }

                        ul {
                            display: flex;
                            padding: 0;
                            align-items: center;
                            justify-content: center;
                            margin: 14px 0 0;
                            li {
                                list-style: none;
                                margin-right: 14px;
                                &:last-child {
                                    margin-right: 0;
                                }
                                img {
                                    width: 70px;
                                    cursor: pointer;
                                    &:hover {
                                        opacity: 0.8;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .signup-banner {
            .my-sumo-button {
                p {
                    font-size: 24px;
                    line-height: 30px;
                }

                ul {
                    li {
                        .primary-btn-sm {
                            width: 170px;
                        }

                        .primary-btn {
                            width: 170px;
                        }
                    }
                }
            }

            form {
                margin: 0 16rem !important;

                .form-group {
                    span {
                        right: 32% !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .main-wrapper {
        .signup-banner {
            .my-sumo-button {
                p {
                    font-size: 24px;
                    line-height: 30px;
                }

                ul {
                    li {
                        .primary-btn-sm {
                            width: 170px;
                        }

                        .primary-btn {
                            width: 170px;
                        }
                    }
                }
            }

            form {
                margin: 0 5rem !important;

                .form-group {
                    span {
                        right: 18% !important;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {
        .signup-banner {
            .my-sumo-button {
                p {
                    font-size: 24px;
                    line-height: 30px;
                }

                ul {
                    li {
                        margin-right: 5px !important;

                        .primary-btn-sm {
                            width: 170px;
                        }

                        .primary-btn {
                            width: 170px;
                        }
                    }
                }
            }

            form {
                margin: 0 0rem !important;

                .form-group {
                    span {
                        right: 9% !important;
                    }
                }

                .popup {
                    top: 18%;
                }
            }
        }
    }

    #signup-page {
        padding: 2rem 0;
    }

    .share-popup-modal {
        .modal-dialog {
            margin: 0 auto;
            width: 100%;
            right: 0;
            left: 0;

            .modal-content {
                margin: 0 12px;
                .modal-header {
    
                    button {
                        &.btn-close {
                            top: 28px;
                            right: 28px;
                        }
                    }
                }
                .modal-body {
                    .share-popup-modal-inner {
                        .share-popup {
                            h2 {
                                font-size: 28px;
                                line-height: 38px;
                            }

                            ul {
                                li {
                                    margin-right: 10px;
                                    img {
                                        width: 51px;
                                    }
                                }
                                
                            }
                        }
                    }
                }
            }
        }
    }
}