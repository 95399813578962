.main-wrapper {
    .home1-img {
        width: 100%;
        background-color: #9d0909e8;
        clip-path: circle(83% at 50% -50%);
        transform: translate(0px, -16px);
    }

    .home1-banner {
        position: relative;
        margin: -111px;
        top: -113px;

        .home-banner-text {
            position: relative;
            top: -16rem;
            left: 0;
            width: 63%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            bottom: 0;

            h1 {
                color: #FCBF49;
                font-size: 56px;
                text-align: center;
                font-weight: 700;
                line-height: 74px
            }
        }

        .search-box {
            text-align: center;
            position: relative;
            top: -14rem;

            .form-group {
                input.search-icon {
                    height: 3.8rem;
                    font-size: 1.2rem;
                    font-style: normal;
                    padding: 0 5.6rem;
                    border-radius: 39px;
                    background-position: 42px;
                    border: 2px solid #ccc;
                    background-color: #fff;
                    margin: 10px auto;
                    width: 50%;
                    outline: 0;
                }

                .search-icon {
                    background-image: url(../images/search-icon.svg);
                    background-position: 40px;
                    background-repeat: no-repeat;
                    background-size: 25px;
                }

                input.search-btn {
                    height: 40px;
                    font-size: 1.2rem;
                    font-style: normal;
                    padding: 0 0.6rem;
                    position: absolute;
                    top: 11px;
                    text-align: center;
                    right: 25rem;
                    border-radius: 39px;
                    color: #fff;
                    background-position: 42px;
                    border: 2px solid #BE1622;
                    background-color: #BE1622;
                    margin: 10px auto;
                    box-shadow: 0px 3px 6px #00000029;
                    width: 150px;
                    outline: 0;
                }

                input.search-btn::placeholder {
                    color: #fff;
                }
            }
        }
    }

    .top-categories {
        padding: 0 0 0rem;

        h1 {
            text-align: center;
            font-size: 34px;
            font-weight: bold;
            color: #003049;
            margin-bottom: 2.5rem;
        }

        .slick-arrow {
            top: 49px;
            display: flex !important;
            justify-content: end;
        }

        .slick-next:before {
            font-size: 26px;
            color: #ffffff00 !important;
            background-image: url(../images/prev-icon.svg);
            width: 16px;
            transform: rotate(180deg);
            height: 27px;
        }

        .slick-prev:before {
            font-size: 26px;
            color: #ffffff00 !important;
            background-image: url(../images/prev-icon.svg);
            width: 16px;
            height: 27px;
            margin-right: 20px;
        }

        h3 {
            font-size: 1.75rem;
            background: #f2f2f2;
            width: 100px;
            height: 100px;
            border-radius: 50px;
            display: flex;
            align-items: center;
            position: relative;
            text-align: center;
            justify-content: center;
            z-index: -1;
            margin: 0 auto 0.8rem;
            img {
                width: 50px;
            }
        }

        .bg-color {
            background: #D62828;
        }

        p {
            text-align: center;
        }
    }

    .trending-deals {
        h2 {
            text-align: center;
            font-size: 35px;
            font-weight: bold;
            color: #003049;
            margin: 4rem 0;
        }

        .trending-deals-box {
            background-color: #fff;
            border-radius: 27px;
            margin-bottom: 2rem;
            height: 400px;
            border: 0.5px solid #707070;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .trending-deals-text {
                padding: 1rem 1rem;

                h3 {
                    font-size: 34px;
                    color: #003049;
                    text-align: center;
                    font-weight: bold;
                    margin-bottom: 2rem;
                }

                ul {
                    list-style: none;
                    margin: 30px 0 17px 0;
                    text-align: center;
                    padding: 0;

                    li {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 10px;
                        background-color: #F5F5F5;
                        border: 0.25px solid #959595;
                        border-radius: 50px;
                        width: 65px;
                        height: 66px;

                        img {
                            width: 30px;
                        }

                        &.active {
                            border: 3px solid #D62828;
                            border-radius: 96px;
                            padding: 10px;
                            background-color: #fff;
                        }

                        &:hover {
                            border: 3px solid #D62828;
                            border-radius: 96px;
                            padding: 8px;
                            background-color: #fff;
                            transform: scale(1.1);
                            transition: all .9s;
                        }
                    }

                    .ms-great {
                        margin-right: 0.9rem;
                    }
                }
            }

            .home-block-img {
                position: relative;
                overflow: hidden;
                width: 100%;

                img {
                    width: 100%;
                }

                .black-shadow {
                    position: absolute;
                    top: 0rem;
                    left: 0;
                    right: 0;
                }

                .social-icon {
                    position: absolute;
                    top: 13px;
                    right: 10px;
                    text-align: end;
                    display: flex;
                    justify-content: end;

                    img {
                        width: 30px;
                        height: 30px;
                        background-color: #f5f5f5;
                        border-radius: 50px;
                        border: 3px solid #f5f5f5;
                    }
                }

                .new-btn {
                    position: absolute;
                    font-size: 12px;
                    top: 0;
                    margin: 14px 0 0 12px;
                    color: #fff;
                    font-weight: 400;
                    background-color: #FCBF49;
                    border-radius: 50px;
                    width: 120px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .yellow-bg {
                    background-color: #F77F00;
                }
            }

            .home-text {
                padding: 13px 13px;
                margin-bottom: 2rem;

                h4 {
                    color: #FCBF49;
                    font-size: 21px;
                    display: flex;
                    font-weight: 700;
                    margin: 0.5rem 0;
                    align-items: flex-end;
                    justify-content: flex-start;

                    i {
                        font-style: inherit;

                        img {
                            margin-right: 13px;
                        }
                    }
                }

                .yellow-color {
                    color: #F77F00;
                }

                h5.grill-house {
                    color: #003049;
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 1rem 0;

                    a {
                        color: #959595;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }
                }

                p {
                    color: #959595;
                    font-size: 12px;
                    margin: 1rem 0;
                }

                .card-list {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 28px;
                    line-height: 36px;
                    color: #959595;
                    font-weight: 500;
                    margin: 1rem 0 0;

                    span {
                        text-decoration: line-through;
                    }

                    strong {
                        color: #FCBF49;
                        font-weight: 900;
                    }

                    .yellow-color {
                        color: #F77F00;
                    }

                    a {
                        color: #fff;
                        font-weight: 400;
                        background-color: #FCBF49;
                        border-radius: 50px;
                        width: 120px;
                        text-decoration: none;
                        font-size: 16px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }

                    .yellow-bg {
                        background-color: #F77F00;
                    }
                }

                .card-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }
    }

    .unmissable-deals {
        background-color: #F8F8F8;

        h2 {
            text-align: center;
            font-size: 35px;
            font-weight: bold;
            color: #003049;
            margin: 4rem 0;
        }

        .unmissable-deals-box {
            background-color: #fff;
            border-radius: 27px;
            margin-bottom: 2rem;
            height: 400px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .home-block-img {
                position: relative;
                overflow: hidden;
                width: 100%;

                img {
                    width: 100%;
                }

                .black-shadow {
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0;
                }

                .social-icon {
                    position: absolute;
                    top: 13px;
                    right: 10px;
                    display: flex;
                    justify-content: end;

                    img {
                        width: 30px;
                        height: 30px;
                        background-color: #f5f5f5;
                        border-radius: 50px;
                        border: 3px solid #f5f5f5;
                    }
                }

                #ms-icon {
                    left: 87%;
                }

                #yellow-ico {
                    left: 87%;
                }

                .new-btn {
                    position: absolute;
                    font-size: 12px;
                    top: 0;
                    font-weight: 400;
                    background-color: #D62828;
                    border-radius: 50px;
                    color: #fff;
                    left: 0px;
                    margin: 14px 0 0 12px;
                    width: 120px;
                    height: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                #yellow-light {
                    width: 150px;
                    background-color: #FCBF49;
                }

                .limited-btn {
                    background-color: #F77F00;
                    width: 150px;
                }
            }

            .home-text {
                padding: 13px 13px;
                margin-bottom: 2rem;

                h4 {
                    color: #D62828;
                    font-size: 21px;
                    font-weight: 700;
                    display: flex;
                    margin: 0.5rem 0;
                    align-items: flex-end;
                    justify-content: flex-start;

                    i {
                        font-style: inherit;

                        img {
                            margin-right: 13px;
                        }

                        b {
                            color: #FCBF49;
                            font-weight: 700;
                        }

                        span {
                            color: #F77F00;
                            font-weight: 700;
                        }
                    }
                }

                h5.grill-house {
                    color: #003049;
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 18px;
                    font-weight: 500;
                    margin: 1rem 0;

                    a {
                        color: #959595;
                        text-decoration: none;
                        font-size: 16px;
                        font-weight: 400;
                        display: flex;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }
                }

                p {
                    color: #959595;
                    font-size: 12px;
                    margin: 1rem 0;
                }

                .card-list {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    font-size: 28px;
                    line-height: 36px;
                    color: #959595;
                    font-weight: 500;
                    margin: 1rem 0 0;

                    span {
                        text-decoration: line-through;
                    }

                    strong {
                        color: #be1622;
                        font-weight: 900;
                    }

                    .light-color {
                        color: #FCBF49;
                    }

                    .col-strong {
                        color: #F77F00;
                    }

                    a {
                        color: #fff;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 120px;
                        text-decoration: none;
                        font-size: 16px;
                        height: 40px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 15px;
                            margin-right: 8px;
                        }
                    }

                    .yellow-ligh {
                        background-color: #FCBF49;
                    }

                    .yellow-bg {
                        background-color: #F77F00;
                    }
                }

                .card-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                button {
                    color: #fff;
                    font-weight: 600;
                    background-color: #D62828;
                    border-radius: 50px;
                    width: 100%;
                    border: 0;
                    font-size: 16px;
                    margin-bottom: 0.5rem;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .one-day-deals {
        height: 749px;
        position: relative;
        overflow: hidden;
        width: 100%;

        .one-deals-img {
            background-color: #be1622;
            width: 100%;
        }

        h1 {
            position: absolute;
            top: 4rem;
            font-weight: bold;
            color: #fff;
            line-height: 50px;
            font-size: 34px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 86%;
            margin: 0 auto;
        }

        p {
            position: absolute;
            top: 16%;
            color: #fff;
            display: flex;
            font-size: 20px;
            justify-content: center;
            align-items: center;
            width: 86%;
            margin: 0 auto;
        }

        .one-day-deals-box {
            background-color: #fff;
            border-radius: 27px;
            margin-bottom: 2rem;
            position: absolute;
            width: 20%;
            top: 26%;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            .home-block-img {
                position: relative;
                overflow: hidden;
                width: 100%;

                img {
                    width: 100%;
                    height: 145px;
                }

                .black-shadow {
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0;
                    height: auto;
                }

                .social-icon {
                    position: absolute;
                    top: 15px;
                    display: flex;
                    justify-content: end;
                    left: 86%;

                    img {
                        width: 28px;
                        height: 28px;
                        background-color: #f5f5f5;
                        border-radius: 50px;
                        border: 3px solid #f5f5f5;
                    }
                }
            }

            .home-text {
                padding: 13px 13px;
                margin-bottom: 0rem;

                h4 {
                    color: #D62828;
                    font-size: 18px;
                    font-weight: 700;
                    display: flex;
                    margin: 0.5rem 0;
                    align-items: flex-end;
                    justify-content: flex-start;

                    i {
                        font-style: inherit;

                        img {
                            margin-right: 6px;
                            width: 16px;
                        }

                        span {
                            color: #FCBF49;
                            font-weight: 700;
                        }

                        b {
                            color: #F77F00;
                            font-weight: 700;

                        }
                    }
                }

                h5.grill-house {
                    color: #003049;
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    font-weight: 500;
                    margin: 1rem 0;

                    a {
                        color: #959595;
                        text-decoration: none;
                        font-size: 13px;
                        font-weight: 400;
                        display: flex;

                        img {
                            width: 12px;
                            margin-right: 8px;
                        }
                    }
                }

                p {
                    color: #959595;
                    font-size: 9px;
                    margin: 0rem 0;
                    text-align: left;
                    top: 50%;
                    position: relative;
                    line-height: 13px;
                }

                .card-list {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 21px;
                    line-height: 36px;
                    color: #959595;
                    font-weight: 500;
                    margin: 0.2rem 0 0;

                    span {
                        text-decoration: line-through;
                    }

                    strong {
                        color: #be1622;
                        font-weight: 900;
                    }

                    .ms2-strong {
                        color: #F77F00;
                    }

                    .ms-2 {
                        color: #FCBF49;
                    }

                    a {
                        color: #fff;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 100px;
                        text-decoration: none;
                        font-size: 12px;
                        height: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 10px;
                            margin-right: 8px;
                        }
                    }

                    .color-yellow {
                        background-color: #FCBF49;
                    }

                    .hr-ms2 {
                        background-color: #F77F00;
                    }
                }

                .card-title {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                }

                button {
                    color: #fff;
                    font-weight: 600;
                    background-color: #D62828;
                    border-radius: 50px;
                    width: 100%;
                    border: 0;
                    font-size: 12px;
                    margin-bottom: 0.5rem;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                    height: 36px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        margin-right: 5px;
                    }
                }

                .remaining-ms2 {
                    background-color: #F77F00;
                }

                .remaining-b {
                    background-color: #FCBF49;
                }
            }
        }

        .acttion-blocked {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            width: 85%;
            margin: 0 auto;
            top: 86%;

            button {
                color: #fff;
                font-weight: 600;
                background-color: #D62828;
                border: 2px solid #fff;
                border-radius: 50px;
                width: 150px;
                font-size: 16px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                &:hover {
                    background-color: #fff;
                    color: #D62828;
                }
            }
        }
    }

    .acttion-block {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 0 2rem;

        button {
            color: #D62828;
            font-weight: 600;
            background-color: #fff;
            border: 2px solid #D62828;
            border-radius: 50px;
            width: 150px;
            font-size: 16px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

            &:hover {
                background-color: #D62828;
                color: #fff;
            }
        }
    }

    .top-categories-mobile {
        display: none;
    }
}

#home1-page {
    .shadow {
        z-index: -1;
        height: 649px;
        content: "";
        -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
        filter: drop-shadow(1px 8px 8px rgba(33, 22, 0, 0.4));
    }
}

@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .home1-img {
            width: 100%;
            clip-path: circle(79% at 50% -36%);
            transform: translate(0px, -10px);
        }

        .home1-banner {
            margin: -111px 0;
            top: -89px;

            .home-banner-text {
                h1 {
                    font-size: 39px;
                    line-height: 50px;
                }
            }

            .search-box {
                .form-group {
                    input.search-icon {
                        width: 72%;
                    }

                    input.search-btn {
                        right: 9rem;
                    }
                }
            }
        }

        .top-categories {
            .slick-prev:before {
                margin-right: 8px;
            }

            h3 {
                width: 80px;
                height: 80px;

                img {
                    width: 38px;
                    height: 50px;
                }
            }
        }

        .trending-deals {
            .trending-deals-box {
                .trending-deals-text {
                    ul {
                        li {
                            width: 60px;
                            height: 60px;
                            margin-right: 5px;
                        }
                    }
                }

                .home-text {
                    h4 {
                        font-size: 16px;
                    }

                    h5.grill-house {
                        font-size: 15px;
                        align-items: center;
                    }

                    .card-list {
                        font-size: 21px;

                        a {
                            font-size: 14px;
                            width: 110px;
                            height: 36px;
                        }
                    }
                }
            }
        }

        .unmissable-deals {
            .unmissable-deals-box {
                .home-text {
                    h4 {
                        font-size: 16px;
                    }

                    h5.grill-house {
                        font-size: 15px;
                        align-items: center;
                    }

                    .card-list {
                        font-size: 21px;

                        a {
                            font-size: 14px;
                            width: 110px;
                            height: 36px;
                        }
                    }
                }
            }
        }

        .one-day-deals {
            .one-day-deals-box {
                .home-block-img {
                    .social-icon {
                        right: 11px;
                    }
                }

                .home-text {
                    h4 {
                        font-size: 13px;
                    }
                }
            }
        }
    }

}


@media only screen and (max-width: 991px) {
    .main-wrapper {
        .home1-img {
            width: 100%;
            clip-path: circle(79% at 50% -36%);
            transform: translate(0px, -10px);
        }

        .home1-banner {
            margin: -111px 0;
            top: -21px;

            .home-banner-text {
                h1 {
                    font-size: 38px;
                    line-height: 45px;
                }
            }

            .search-box {
                .form-group {
                    input.search-icon {
                        width: 72%;
                    }

                    input.search-btn {
                        right: 7rem;
                    }
                }
            }
        }

        .top-categories {
            .slick-prev:before {
                margin-right: 8px;
            }

            h3 {
                width: 60px;
                height: 60px;

                img {
                    width: 25px;
                    height: 29px;
                }
            }
        }

        .trending-deals {
            .trending-deals-box {
                border-radius: 39px;
                height: auto;

                .home-text {
                    h4 {
                        font-size: 25px;
                    }

                    h5.grill-house {
                        font-size: 21px;
                        align-items: center;
                    }

                    p {
                        font-size: 20px;
                    }

                    .card-list {
                        font-size: 32px;

                        a {
                            font-size: 17px;
                            width: 140px;
                            height: 50px;
                        }
                    }
                }
            }
        }

        .unmissable-deals {
            .unmissable-deals-box {
                border-radius: 39px;
                height: auto;

                .home-text {
                    h4 {
                        font-size: 25px;
                    }

                    h5.grill-house {
                        font-size: 21px;
                        align-items: center;
                    }

                    p {
                        font-size: 20px;
                    }

                    .card-list {
                        font-size: 32px;

                        a {
                            font-size: 17px;
                            width: 140px;
                            height: 50px;
                        }
                    }
                }
            }
        }
    }
}



@media only screen and (max-width: 767px) {
    .main-wrapper {
        .home-banner {
            // margin: -10rem 0;

            .home-banner-img {
                top: 0rem !important;

                .banner-icon {
                    position: relative;
                    width: 122px !important;
                }

                .camera-icon {
                    width: 30px !important;
                    top: -64px;
                    right: 36%;
                    position: absolute;
                }
            }

            .my-sumo-button {
                margin: 0px;
                top: 113px !important;

                ul {
                    overflow: auto;
                    justify-content: flex-start !important;
                    padding: 0rem 0 0 1rem !important;

                    li {
                        a {
                            font-size: 14px !important;
                            width: 175px !important;
                            height: 50px !important;
                        }
                    }
                }
            }
        }

        .takeaway {
            .my-sumo-button {
                top: 92px !important;

                h2 {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }

        // .home-img {
        //     display: none;
        // }

        .home-mobile-banner {
            display: block;
            width: 100%;
            height: 445px;
            background-color: #a80707e3;
            clip-path: circle(135% at 50% -34%);
            transform: translate(0px, -7px);

        }

        .home-link-list {
            .justify-content-center {
                justify-content: flex-start !important;

                .fixed-tab {
                    margin: -11px;

                    ul {
                        justify-content: flex-start;
                        overflow: auto;
                    }
                }
            }
        }

        .top-categories {
            display: none;
        }

        .top-categories-mobile {
            display: block;

            .slick-slider {

                .slick-list {
                    margin: 0 -12px;

                    .slick-track {
                        display: flex;
                        margin-left: 10px;
                    }
                }

                .slick-arrow {
                    display: none !important;
                }
            }

            h3 {
                font-size: 1.75rem;
                background: #f2f2f2;
                width: 100px;
                height: 100px;
                border-radius: 50px;
                display: flex;
                align-items: center;
                position: relative;
                text-align: center;
                justify-content: center;
                z-index: -1;
            }

            .bg-color {
                background: #D62828;
            }

            p {
                text-align: center;
            }

            h1 {
                text-align: center;
                font-size: 34px;
                font-weight: bold;
                color: #003049;
                margin-bottom: 2.5rem;
            }

            .slick-arrow {
                display: none !important;

                .slick-next:before {
                    display: none;
                }

                .slick-prev:before {
                    display: none;
                }

            }
        }

        .trending-deals-mobile {
            .slick-slider {
                .slick-list {
                    margin: 0 -12px;

                    .slick-track {
                        display: flex;
                    }
                }

                .slick-arrow {
                    display: none !important;
                }
            }

            h2 {
                text-align: center;
                font-size: 35px;
                font-weight: bold;
                color: #003049;
                margin: 4rem 0;
            }

            .trending-deals-box {
                background-color: #fff;
                border-radius: 27px;
                margin-bottom: 2rem;
                margin-right: 10px;
                margin-left: 10px;
                height: 400px;
                border: 0.5px solid #707070;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .trending-deals-text {
                    padding: 1rem 1rem;

                    h3 {
                        font-size: 24px;
                        color: #003049;
                        text-align: center;
                        font-weight: bold;
                        margin-bottom: 2rem;
                    }

                    ul {
                        list-style: none;
                        margin: 0;
                        text-align: center;
                        padding: 0;

                        li {
                            display: inline-block;
                            margin-bottom: 1rem;
                            margin-right: 0.5rem;

                            &:last-child {
                                margin-right: 0;
                            }

                            p {
                                margin: 0;
                                font-size: 15px;
                            }
                        }
                    }
                }

                .home-block-img {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                    }

                    .black-shadow {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        right: 0;
                    }

                    .social-icon {
                        position: absolute;
                        top: 13px;
                        right: 10px;
                        display: flex;
                        justify-content: end;

                        img {
                            width: 30px;
                            height: 30px;
                            background-color: #f5f5f5;
                            border-radius: 50px;
                            border: 3px solid #f5f5f5;
                        }
                    }

                    .new-btn {
                        position: absolute;
                        font-size: 12px;
                        top: 0px;
                        left: 0%;
                        margin: 1rem 0.5rem;
                        color: #fff;
                        font-weight: 400;
                        background-color: #FCBF49;
                        border-radius: 50px;
                        width: 120px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .yellow-bg {
                        background-color: #F77F00;
                    }
                }

                .home-text {
                    padding: 13px 13px;
                    margin-bottom: 2rem;

                    h4 {
                        color: #FCBF49;
                        font-size: 20px;
                        display: flex;
                        font-weight: 700;
                        margin: 0.5rem 0;
                        align-items: flex-end;
                        justify-content: flex-start;

                        i {
                            display: contents;
                            font-style: inherit;

                            img {
                                margin-right: 13px;
                            }
                        }
                    }

                    .yellow-colors {
                        color: #F77F00;
                        font-size: 18px;
                    }

                    h5.grill-house {
                        color: #003049;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 17px;
                        font-weight: 500;
                        margin: 1rem 0;

                        a {
                            color: #959595;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: 400;
                            display: flex;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }
                    }

                    p {
                        color: #959595;
                        font-size: 12px;
                        margin: 1rem 0;
                    }

                    .card-list {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        font-size: 24px;
                        line-height: 36px;
                        color: #959595;
                        font-weight: 500;
                        margin: 1rem 0 0;

                        span {
                            text-decoration: line-through;
                        }

                        strong {
                            color: #FCBF49;
                            font-weight: 900;
                        }

                        .yellow-color {
                            color: #F77F00;
                        }

                        a {
                            color: #fff;
                            font-weight: 400;
                            background-color: #FCBF49;
                            border-radius: 50px;
                            width: 110px;
                            text-decoration: none;
                            font-size: 15px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }

                        .yellow-bg {
                            background-color: #F77F00;
                        }
                    }

                    .card-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }

        .unmissable-deals-mobile {
            background-color: #F8F8F8;

            .slick-slider {
                .slick-list {
                    margin: 0 -12px;

                    .slick-track {
                        display: flex;
                    }
                }

                .slick-arrow {
                    display: none !important;
                }
            }

            h2 {
                text-align: center;
                font-size: 35px;
                font-weight: bold;
                color: #003049;
                margin: 4rem 0;
            }

            .unmissable-deals-box {
                background-color: #fff;
                border-radius: 27px;
                margin-bottom: 2rem;
                margin-right: 10px;
                margin-left: 10px;
                height: 400px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .home-block-img {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    img {
                        width: 100%;
                    }

                    .black-shadow {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        right: 0;
                    }

                    .social-icon {
                        position: absolute;
                        top: 15px;
                        display: flex;
                        justify-content: end;
                        right: 14px;

                        img {
                            width: 30px;
                            height: 30px;
                            background-color: #f5f5f5;
                            border-radius: 50px;
                            border: 3px solid #f5f5f5;
                        }
                    }

                    #ms-icon {
                        left: 87%;
                    }

                    #yellow-ico {
                        left: 87%;
                    }

                    .new-btn {
                        position: absolute;
                        font-size: 12px;
                        top: 0px;
                        margin: 1rem 0.5rem;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        color: #fff;
                        left: 0px;
                        width: 120px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    #yellow-light {
                        width: 150px;
                        background-color: #FCBF49;
                    }

                    .limited-btn {
                        width: 150px;
                        background-color: #F77F00;
                    }
                }

                .home-text {
                    padding: 13px 13px;
                    margin-bottom: 2rem;

                    h4 {
                        color: #D62828;
                        font-size: 18px;
                        font-weight: 700;
                        display: flex;
                        margin: 0.5rem 0;
                        align-items: flex-end;
                        justify-content: flex-start;

                        i {
                            display: flex;
                            font-style: inherit;

                            img {
                                margin-right: 13px;
                            }

                            b {
                                color: #FCBF49;
                                font-weight: 700;
                            }

                            span {
                                color: #F77F00;
                                font-weight: 700;
                            }
                        }
                    }

                    h5.grill-house {
                        color: #003049;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 17px;
                        font-weight: 500;
                        margin: 1rem 0;

                        a {
                            color: #959595;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: 400;
                            display: flex;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }
                    }

                    p {
                        color: #959595;
                        font-size: 12px;
                        margin: 1rem 0;
                    }

                    .card-list {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        font-size: 24px;
                        line-height: 36px;
                        color: #959595;
                        font-weight: 500;
                        margin: 1rem 0 0;

                        span {
                            text-decoration: line-through;
                        }

                        strong {
                            color: #be1622;
                            font-weight: 900;
                        }

                        .light-color {
                            color: #FCBF49;
                        }

                        .col-strong {
                            color: #F77F00;
                        }

                        a {
                            color: #fff;
                            font-weight: 400;
                            background-color: #D62828;
                            border-radius: 50px;
                            width: 110px;
                            text-decoration: none;
                            font-size: 15px;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }

                        .yellow-ligh {
                            background-color: #FCBF49;
                        }

                        .yellow-bg {
                            background-color: #F77F00;
                        }
                    }

                    .card-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    button {
                        color: #fff;
                        font-weight: 600;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 100%;
                        border: 0;
                        font-size: 16px;
                        margin-bottom: 0.5rem;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }

        .one-day-deals-mobile {
            background-image: url(../images/one-banner-mobile.png);
            background-color: #be1622;
            padding: 42px 0;
            background-size: 100%;

            .slick-slider {
                .slick-list {
                    margin: 0 -12px;

                    .slick-track {
                        display: flex;
                    }
                }

                .slick-arrow {
                    display: none !important;
                }
            }

            .one-deals-img {
                width: 100%;
            }

            h1 {
                font-weight: bold;
                text-align: center;
                color: #fff;
            }

            p {
                text-align: center;
                color: #fff;
            }

            .one-day-deals-box {
                background-color: #fff;
                border-radius: 20px;
                margin-bottom: 2rem;
                margin-right: 10px;
                margin-left: 10px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .home-block-img {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    img {
                        width: 100%;
                        height: 150px;
                    }

                    .black-shadow {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        right: 0;
                        height: auto;
                    }

                    .social-icon {
                        display: flex;
                        justify-content: end;
                        position: absolute;
                        top: 13px;
                        right: 10px;

                        img {
                            width: 30px;
                            height: 30px;
                            background-color: #f5f5f5;
                            border-radius: 50px;
                            border: 3px solid #f5f5f5;
                        }
                    }
                }

                .home-text {
                    padding: 13px 13px;
                    margin-bottom: 0rem;

                    h4 {
                        color: #D62828;
                        font-size: 22px;
                        font-weight: 700;
                        display: flex;
                        margin: 0.5rem 0;
                        align-items: flex-end;
                        justify-content: flex-start;

                        i {
                            display: flex;
                            font-style: inherit;
                            align-items: flex-end;

                            img {
                                margin-right: 6px;
                                width: 20px;
                            }

                            span {
                                color: #FCBF49;
                                font-weight: 700;
                            }

                            b {
                                color: #F77F00;
                                font-weight: 700;

                            }
                        }
                    }

                    h5.grill-house {
                        color: #003049;
                        display: flex;
                        justify-content: space-between;
                        font-size: 14px;
                        font-weight: 500;
                        margin: 1rem 0;

                        a {
                            color: #959595;
                            text-decoration: none;
                            font-size: 13px;
                            font-weight: 400;
                            display: flex;

                            img {
                                width: 12px;
                                margin-right: 8px;
                            }
                        }
                    }

                    p {
                        color: #959595;
                        font-size: 9px;
                        margin: 0rem 0;
                        text-align: left;
                        top: 0;
                        line-height: 13px;
                    }

                    .card-list {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-size: 21px;
                        line-height: 36px;
                        color: #959595;
                        font-weight: 500;
                        margin: 0.2rem 0 0;

                        span {
                            text-decoration: line-through;
                        }

                        strong {
                            color: #be1622;
                            font-weight: 900;
                        }

                        .ms2-strong {
                            color: #F77F00;
                        }

                        .ms-2 {
                            color: #FCBF49;
                        }

                        a {
                            color: #fff;
                            font-weight: 400;
                            background-color: #D62828;
                            border-radius: 50px;
                            width: 100px;
                            text-decoration: none;
                            font-size: 12px;
                            height: 25px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 10px;
                                margin-right: 8px;
                            }
                        }

                        .color-yellow {
                            background-color: #FCBF49;
                        }

                        .hr-ms2 {
                            background-color: #F77F00;
                        }
                    }

                    .card-title {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                    }

                    button {
                        color: #fff;
                        font-weight: 600;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 100%;
                        border: 0;
                        font-size: 12px;
                        margin-bottom: 0.5rem;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            margin-right: 5px;
                        }
                    }

                    .remaining-ms2 {
                        background-color: #F77F00;
                    }

                    .remaining-b {
                        background-color: #FCBF49;
                    }
                }
            }

            .acttion-blocked {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                button {
                    color: #fff;
                    font-weight: 600;
                    background-color: #D62828;
                    border: 2px solid #fff;
                    border-radius: 50px;
                    width: 100%;
                    font-size: 16px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                    &:hover {
                        background-color: #fff;
                        color: #D62828;
                    }
                }
            }
        }

        .home1-banner {
            margin: -77px 0;
            height: 0;

            .home-banner-text {
                top: -18rem;
                width: 100%;

                h1 {
                    font-size: 40px;
                    line-height: 50px
                }
            }

            .search-box {
                top: -17rem;

                .form-group {
                    input.search-icon {
                        width: 100%;
                        font-size: 13px;
                        padding: 0 4.6rem;
                    }

                    .search-icon {
                        background-size: 20px;
                    }

                    input.search-btn {
                        font-size: 0.9rem;
                        position: relative;
                        top: -69px;
                        left: 118px;
                        right: 0;
                        width: 100px;
                        height: 35px;
                    }

                    input.search-btn::placeholder {
                        color: #fff;
                    }
                }
            }
        }
    }
}

// @media only screen and (max-device-width: 430px) {
//     .main-wrapper {
//         .takeaway {
//             .my-sumo-button {
//                 top: 92px !important;

//                 h2 {
//                     font-size: 27px;
//                     line-height: 34px;
//                     margin-top: 24px;
//                 }
//             }
//         }
//     }

// }