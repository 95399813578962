header {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;
    padding: 20px 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px;
    .justify-content-center {
        align-items: center !important;
    }
    .header-left {
        .logo {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                width: 238px;
            }
        }
    }
    .header-right {
        display: flex;
        align-items: center;
        justify-content: end;
        .search-icon {
            img {
                display: block;
                margin-right: 27px;
            }
        }
        ul {
            padding: 0;
            margin: 0;
            list-style: 0;
            li {
                display: inline-block;
                margin-right: 10px;
                a {
                    color: #D62828;
                    font-size: 18px;
                    text-decoration: none;
                    border: 2px solid #D62828;
                    border-radius: 50px;
                    width: 150px;
                    line-height: 25px;
                    display: flex;
                    align-items: center;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    justify-content: center;
                    height: 50px;
                    font-weight: 500;
                    &:hover {
                        background-color: #D62828;
                        color: #fff;
                    }
                    &.active {
                        background-color: #D62828;
                        color: #fff;
                    }
                }
                .primary-btn {
                    color: #F77F00;
                    font-size: 18px;
                    text-decoration: none;
                    border: 2px solid #F77F00;
                    border-radius: 50px;
                    width: 150px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 50px;
                    font-weight: 500;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    &:hover {
                        background: #F77F00;
                        color: #fff;
                    }
                    &.active {
                        background: #F77F00;
                        color: #fff;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .menu-icon {
            margin-left: 27px;
        }
    }
}

@media only screen and (max-width: 1199px) {
    
}


@media only screen and (max-width: 991px) {
    header {
        .header-right {
            position: absolute;
            top: 28px;
            right: 35px;
        }
    }
}


@media only screen and (max-width: 767px) {
    header {
        padding: 18px 0;
        .justify-content-center {
            align-items: center !important;
        }
        .header-left {
            .logo {
                display: flex;
                align-items: center;
                img {
                    width: 156px;
                }
            }
        }
        .header-right {
            display: flex;
            position: absolute;
            top: 22px;
            left: 0;
            right: 11px;
            .search-icon {
                img {
                    margin-right: 12px;
                    width: 15px;
                }
            }
            ul {
                li {
                    margin-right: 7px;
                    a {
                        font-size: 13px;
                        width: 70px;
                        height: 30px;
                        line-height: 12px;
                    }
                    .primary-btn {
                        font-size: 13px;
                        width: 70px;
                        height: 30px;

                    }
                }
            }
            .menu-icon {
                margin-left: 8px;
                width: 20px;
            }
        }
    } 
}