#thank-you {
    background-color: #F2F2F2;
    padding: 105px 0;
    text-align: center;

    .thankyou-img {
        img {
            margin-bottom: 19px;
        }
    }

    .thankyou-content {
        h2 {
            font-size: 34px;
            // height: 90px;
            font-family: Co Headline Bold;
            color: #D62828;
            margin: 0 18rem 20px;
            font-weight: bold;
            line-height: 38px;
        }

        p {
            font-size: 17px;
            font-family: "Lato-Regular";
            line-height: 27px;
            margin: 0 auto 30px;
            font-weight: 600;
            width: 50%;
            color: #444444;
        }
    }

    .thankyou-box {
        background-image: url(../images/awesome-img.png);
        background-color: #D62828;
        width: 50%;
        height: auto;
        position: relative;
        overflow: hidden;
        padding: 23px 0;
        margin: 0 auto;
        border-radius: 25px;
        background-size: cover;

        .text-center {
            h2 {
                font-size: 24px;
                line-height: 30px;
                font-family: Co Headline;
                color: #fff;
                margin-bottom: 14px;
                font-weight: 600;
                letter-spacing: 1.2px;
                padding: 0 14px;
            }
            p {
                color: #fff;
                font-size: 17px;
                line-height: 22px;
                font-family: Lato-Regular;
                font-weight: 500;
                padding: 0 20px;
            }

            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    display: inline-block;
                    margin-right: 11px;
                    cursor: pointer;

                    &:last-child {
                        margin-right: 0;
                    }

                    img {
                        width: 55px;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-device-width: 1366px) {
    #thank-you {
        .thankyou-content {
            h2 {
                margin: 0 17rem 20px;
            }
        }
    }
}

@media only screen and (max-width: 991px) {
    #thank-you {
        padding: 35px 0;

        .thankyou-img {
            img {
                margin-bottom: 18px;
            }
        }

        .thankyou-content {
            h2 {
                font-size: 34px;
                line-height: 40px;
                margin-bottom: 16px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
                margin: 0 auto 20px;
                width: 100%;
            }
        }

        .thankyou-box {
            width: 100%;
            background-size: contain;

            .text-center {
                h2 {
                    font-size: 21px;
                    line-height: 30px;
                    margin-bottom: 15px;
                }

                ul {
                    margin-top: 12px;
                    padding-bottom: 25px;

                    li {
                        margin-right: 7px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            width: 35px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    #thank-you {
        padding: 35px 0 32px;

        .thankyou-img {
            img {
                margin-bottom: 11px;
            }
        }

        .thankyou-content {
            h2 {

                line-height: 40px;
                height: 78px;
                margin: 0 0 24px;
            }

            p {
                font-size: 18px;
                line-height: 28px;
                margin: 0 auto 30px;
                width: 100%;
                font-weight: 500;
            }
        }

        .thankyou-box {
            width: 100%;
            background-size: contain;
            padding: 0;
            height: 100%;

            .text-center {

                h2 {
                    font-size: 17px;
                    line-height: 23px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    padding: 20px 0 0;
                    height: auto;

                    &.text-headding {
                        padding: 16px 0 23px;
                        height: 71px;
                    }
                }
                p {
                    font-size: 14px;
                    line-height: 20px;
                    padding: 0 0px;
                    margin-bottom: 10px;
                }

                ul {
                    li {
                        margin-right: 7px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            width: 35px;
                        }
                    }
                }
            }
        }
    }
}