.main-wrapper {
    // .home-mobile-banner {
    //     display: none;
    // }

    .marketing-text {
        text-align: center;
        padding: 0rem 0 2.5rem;

        h2 {
            text-align: center;
            font-family: sans-serif;
            font-weight: bold;
            font-size: 34px;
            margin-bottom: 0rem;
            color: #003049;
        }

        p {
            font-size: 17px;
            font-weight: 500;
            color: #444444;
            line-height: 50px;
            margin: 1.5rem 0;
        }

        button {
            display: none;
        }

        .cheak-text {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            ul {
                margin: 0;
                padding: 1.3rem 0;
                list-style: none;

                li {
                    text-align: left;
                    margin-bottom: 0.7rem;
                    .content {
                        display: block;
                        position: relative;
                        padding-left: 35px;
                        text-align: left;
                        margin-bottom: 12px;
                        cursor: pointer;
                        font-size: 14px;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        input {
                            position: absolute;
                            opacity: 0;
                            cursor: pointer;
                            height: 0;
                            width: 0;
                        }
                        .checkmark {
                            position: absolute;
                            top: 5px;
                            left: 0;
                            height: 23px;
                            width: 23px;
                            border-radius: 5px;
                            background-color: #D62828;
                        }
                        input:checked ~ .checkmark {
                            background-color: #D62828;
                        }
                        .checkmark:after {
                            content: "";
                            position: absolute;
                            display: none;
                        }
                        input:checked ~ .checkmark:after {
                            display: block;
                        }
                        .checkmark:after {
                            left: 9px;
                            top: 4px;
                            width: 7px;
                            height: 12px;
                            border: solid white;
                            border-width: 0 3px 3px 0;
                            -webkit-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            transform: rotate(45deg);
                        }                 
                    } 
                }
            }
        }
    }
}


@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .marketing-text {
            h2 {
                padding-top: 12px;
            }
            button {
                font-weight: 600;
                color: #fff;
                background-color: #D62828;
                border-radius: 50px;
                width: 34%;
                border: 0;
                font-size: 16px;
                margin: 0 auto;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}


@media only screen and (max-width: 991px) {
    .main-wrapper {
        .marketing-text {
            h2 {
                padding-top: 12px;
            }
            button {
                color: #fff;
                font-weight: 600;
                background-color: #D62828;
                border-radius: 50px;
                width: 34%;
                border: 0;
                font-size: 16px;
                margin: 0 auto;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {
        .marketing-text {
            padding: 0rem 1rem 2rem;
            h2 {
                padding-top: 0px;
                font-size: 29px;
                line-height: 36px;
            }
            p{
                font-size: 15px;
                line-height: 21px;
                font-weight: bold;
            }
            button {
                width: 100%;
            }
        }
    }
}

// @media only screen and (max-width: 767px) {
//     .main-wrapper {
//         .home-banner {

//             .my-sumo-button {
//                 p {
//                     font-size: 24px;
//                     line-height: 30px;
//                     width: 100%;
//                 }

//                 ul {
//                     li {
//                         a {
//                             width: 170px;
//                         }
//                     }
//                 }
//             }
//         }

//         .home-mobile-banner {
//             display: block;
//             width: 100%;
//             height: 445px;
//             background-color: #d12526;
//             clip-path: circle(135% at 50% -34%);
//             transform: translate(0px, -7px);

//         }

//         .marketing-text {
//             h2 {
//                 font-size: 31px;
//             }

//             p {
//                 font-size: 16px;
//                 line-height: 21px;
//                 margin: 1rem 0 2rem;
//                 font-weight: 600;
//             }

//             button {
//                 color: #fff;
//                 font-weight: 600;
//                 background-color: #D62828;
//                 border-radius: 50px;
//                 width: 93%;
//                 border: 0;
//                 font-size: 16px;
//                 margin: 0 auto;
//                 box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
//                 height: 50px;
//                 display: flex;
//                 align-items: center;
//                 justify-content: center;
//             }
//         }
//     }
// }