@font-face {
    font-family: Co Headline Bold;
    src: url("../../../public/Co Headline Bold.otf");
}

@font-face {
    font-family: Co Headline;
    src: url("../../../public/Co Headline.otf");
}

@font-face {
    font-family: Lato-Regular;
    src: url("../../../public/Lato-Regular.ttf");
}

@font-face {
    font-family: Lato-Black;
    src: url("../../../public/Lato-Black.ttf");
}

.main-wrapper {
    position: relative;

    .shadow {
        // z-index: -1;
        content: "";
        height: 500px;
        -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
        filter: drop-shadow(1px 8px 8px rgba(33, 22, 0, 0.4));
        position: relative;

        .home-img {
            width: 100%;
            background-color: #be1622;
            clip-path: circle(79% at 51% -65%);
            transform: translate(0px, -15px);
        }

        .Ellipse-bg {
            position: absolute;
            width: 100%;
            left: 0;
            z-index: -1;

        }
    }

    .shadow2 {
        // z-index: -1;
        content: "";
        height: 535px;
        -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
        filter: drop-shadow(1px 8px 8px rgba(33, 22, 0, 0.4));
        position: relative;

        .home-img2 {
            width: 100%;
            background-color: #be1622;
            clip-path: circle(84% at 51% -65%);
            transform: translate(0px, -15px);
        }
    }

    .home-mobile-banner {
        display: none;
    }

    &.questionnaire-bg {
        background-color: #f2f2f2;
    }

    .home-banner {
        position: absolute;
        right: 0;
        top: 50px;
        left: 0;
        display: inline;

        &.takeaway {
            top: 85px;
        }

        .home-banner-img {
            position: relative;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 0;
            bottom: 0;

            .banner-icon {
                width: 122px;
                border: 3px solid #fff;
                border-radius: 83px;
            }

            .camera-icon {
                width: 30px;
                position: relative;
                top: -35px;
                border: 0;
                right: 32px;
            }
        }

        .my-sumo-button {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            top: 24px;

            h2 {
                color: #fcbf49;
                font-size: 38px;
                font-family: Co Headline Bold;
                width: 52%;
                margin: 0 auto;
                text-align: center;
                line-height: 54px;
                font-weight: bold;
            }

            ul {
                display: inline-flex;
                flex-wrap: nowrap;
                justify-content: center;
                margin: 0 auto;
                padding: 0.7rem 0 3rem;
                white-space: nowrap;
                width: 100%;

                &::-webkit-scrollbar {
                    display: none;
                }

                li {
                    display: inline-block;
                    margin-right: 10px;

                    a {
                        color: #fff;
                        font-size: 14px;
                        text-decoration: none;
                        border: 2px solid #fff;
                        border-radius: 50px;
                        background: #D62828;
                        width: 185px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        font-weight: 500;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

                        &:hover {
                            background-color: #F77F00;
                        }

                        &.active {
                            background-color: #F77F00;
                            color: #fff;
                        }
                    }

                    :last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .quest-text {
            text-align: center;

            h2 {
                color: #fcbf49;
                font-size: 38px;
                font-family: Co Headline Bold;
                width: 47%;
                margin: 0 auto;
                text-align: center;
                line-height: 45px;
                padding-top: 12px;
                font-weight: bold;
            }

            p {
                color: #fff;
                font-size: 18px;
                line-height: 28px;
                width: 35%;
                margin: 30px auto 0;
                font-weight: 500;
                font-family: "Lato-Regular";
            }
        }
    }

    .home-link-list {
        .justify-content-center {
            justify-content: center !important;

            .fixed-tab {
                ul {
                    display: inline-flex;
                    flex-wrap: nowrap;
                    justify-content: center;
                    margin: 0 auto;
                    padding: 0.7rem 0 3rem;
                    white-space: nowrap;
                    width: 100%;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    li {
                        padding: 0.5rem;
                        list-style: none;
                        cursor: pointer;

                        a {
                            border: 2px solid #D62828;
                            color: #D62828;
                            border-radius: 50px;
                            width: 150px;
                            height: 50px;
                            cursor: pointer;
                            font-size: 15px;
                            display: flex;
                            align-items: center;
                            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                            justify-content: center;
                            text-decoration: none;
                            line-height: 1.5;

                            &:hover {
                                background-color: #D62828;
                                color: #fff;
                            }

                            &.active {
                                background-color: #D62828;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .home-list-block {
                background-color: #fff;
                border-radius: 20px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                .home-block-img {
                    position: relative;
                    overflow: hidden;
                    width: 100%;

                    img {
                        width: 100%;
                    }

                    .box-opacity {
                        opacity: 50%;
                    }

                    .black-shadow {
                        position: absolute;
                        top: 0px;
                        left: 0;
                        right: 0;
                    }

                    .box-opacity {
                        opacity: 60%;
                    }

                    .social-icon {
                        position: absolute;
                        top: 13px;
                        display: flex;
                        justify-content: end;
                        right: 10px;

                        img {
                            width: 30px;
                            height: 30px;
                            background-color: #f5f5f5;
                            border-radius: 50px;
                            border: 3px solid #f5f5f5;
                        }
                    }

                    .new-btn {
                        position: absolute;
                        font-size: 12px;
                        top: 14px;
                        font-weight: 400;
                        background-color: #D62828;
                        border-radius: 50px;
                        color: #fff;
                        left: 12px;
                        width: 120px;
                        height: 35px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .gray-light {
                        background-color: #F2F2F2;
                        color: #808080;
                    }

                    .yellow-light {
                        background-color: #FCBF49;
                    }

                    .yellow-bg {
                        background-color: #F77F00;
                    }
                }

                .home-text {
                    padding: 13px 13px;
                    margin-bottom: 2rem;

                    h4 {
                        color: #D62828;
                        font-size: 21px;
                        font-weight: bold;
                        display: flex;
                        margin: 0.5rem 0;
                        align-items: flex-end;
                        justify-content: flex-start;

                        i {
                            font-style: inherit;

                            img {
                                margin-right: 13px;
                            }
                        }
                    }

                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        display: flex;
                        justify-content: center;

                        li {
                            display: inline-block;
                            margin-right: 10px;

                            &:last-child {
                                margin-right: 0;
                            }

                            .primary-btn-sm {
                                color: #fff;
                                font-size: 15px;
                                text-decoration: none;
                                border: 2px solid #D62828;
                                border-radius: 50px;
                                background-color: #D62828;
                                width: 100%;
                                padding: 0 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 50px;
                                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                                font-weight: 500;
                            }

                            img {
                                margin-right: 5px;
                                width: 20px;
                            }

                            .primary-btn {
                                color: #fff;
                                font-size: 15px;
                                text-decoration: none;
                                border: 2px solid #F77F00;
                                border-radius: 50px;
                                background-color: #F77F00;
                                width: 100%;
                                padding: 0 21px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 50px;
                                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                                font-weight: 500;
                            }
                        }
                    }

                    .gray-color {
                        color: #959595;
                    }

                    .yellow-light {
                        color: #FCBF49;
                    }

                    h5.grill-house {
                        color: #003049;
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        font-size: 18px;
                        font-weight: 500;
                        margin: 1rem 0;

                        a {
                            color: #959595;
                            text-decoration: none;
                            font-size: 16px;
                            font-weight: 400;
                            display: flex;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }
                    }

                    h5.gray-color {
                        color: #959595;
                    }

                    .yellow-color {
                        color: #F77F00;
                    }

                    p {
                        color: #959595;
                        font-size: 12px;
                        margin: 1rem 0;
                    }

                    .card-list {
                        display: flex;
                        align-items: baseline;
                        justify-content: space-between;
                        font-size: 28px;
                        line-height: 36px;
                        color: #959595;
                        font-weight: 500;
                        margin: 1rem 0 0;

                        span {
                            text-decoration: line-through;
                        }

                        strong {
                            color: #be1622;
                            font-weight: 900;
                        }

                        .gray-color {
                            color: #959595;
                        }

                        .yellow-light {
                            color: #FCBF49;
                        }

                        .yellow-color {
                            color: #F77F00;
                        }

                        a {
                            color: #fff;
                            font-weight: 400;
                            background-color: #D62828;
                            border-radius: 50px;
                            width: 120px;
                            text-decoration: none;
                            font-size: 16px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 15px;
                                margin-right: 8px;
                            }
                        }

                        .bg-gray {
                            background-color: #959595;
                        }

                        .yellow-light-bg {
                            background-color: #FCBF49;
                        }

                        .yellow-bg {
                            background-color: #F77F00;
                        }
                    }

                    .card-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    button {
                        color: #fff;
                        font-weight: 600;
                        background-color: #D62828;
                        border-radius: 50px;
                        width: 100%;
                        border: 0;
                        font-size: 16px;
                        margin-bottom: 0.5rem;
                        box-shadow: rgba(50, 50, 93, 0.25) 0px 3px 5px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    .gray-light {
                        background-color: #F2F2F2;
                        color: #959595;
                    }

                    .gray-bg {
                        background-color: #959595;
                    }

                    .yellow-light-bg {
                        background-color: #FCBF49;
                    }

                    .yellow-bg {
                        background-color: #F77F00;
                    }
                }
            }

            .benefits-boxs {
                position: relative;
                margin-bottom: 42px;

                img.benefits-background {
                    width: 100%;
                    margin: 0px;
                    background-color: #D62828;
                    border-radius: 25px;
                }

                .benefits-text {
                    position: absolute;
                    top: 0px;
                    left: 0;
                    right: 0;
                    width: 71%;
                    margin: 0 auto;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 0;

                    h2 {
                        color: #fff;
                        font-family: Co Headline Bold;
                        font-size: 24px;
                        line-height: 30px;
                        text-align: center;
                        font-weight: bold;
                        margin: 24px 0;
                        letter-spacing: 0.3px;
                        height: 71px;
                    }
                }
            }

            .home-banner-img {
                h2 {
                    color: #D62828;
                    font-size: 34px;
                    font-family: Co Headline Bold;
                    line-height: 50px;
                    margin: 20px 0;
                    font-weight: bold;
                }

                p {
                    font-size: 18px;
                    line-height: 28px;
                    color: #003049;
                    font-weight: 500;
                    width: 97%;
                    margin: 0 auto 30px;
                    font-family: "Lato-Regular";
                }
            }

            .acttion-block {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin: 0 0 2rem;

                button {
                    color: #D62828;
                    font-weight: 600;
                    background-color: #fff;
                    border: 2px solid #D62828;
                    border-radius: 50px;
                    width: 150px;
                    font-size: 16px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

                    &:hover {
                        background-color: #D62828;
                        color: #fff;
                    }
                }
            }
        }

        ul {
            display: flex;
            justify-content: center;
            margin-bottom: 50px;

            li {
                list-style: none;
                border: 3px solid #00a4dd;
                border-radius: 25px;
                padding: 12px 10px;
                margin-right: 15px;
                width: 190px;

                &:last-child {
                    margin-right: 0;
                }

                &:nth-child(2) {
                    border-color: #009245;
                }

                &:nth-child(3) {
                    border-color: #e0196a;
                }

                &:nth-child(4) {
                    border-color: #db1928;
                }

                &:nth-child(5) {
                    border-color: #f15a24;
                }

                &:nth-child(2) {
                    .bene-item {
                        h5 {
                            strong {
                                color: #009245;
                            }
                        }
                    }

                }

                &:nth-child(3) {
                    .bene-item {
                        h5 {
                            strong {
                                color: #e0196a;
                            }
                        }
                    }
                }

                &:nth-child(4) {
                    .bene-item {
                        h5 {
                            strong {
                                color: #db1928;
                            }
                        }
                    }
                }

                &:nth-child(5) {
                    .bene-item {
                        h5 {
                            strong {
                                color: #f15a24;
                            }
                        }
                    }
                }

                .bene-item {
                    h5 {
                        font-size: 12px;
                        line-height: 17px;
                        color: #003049;
                        margin-bottom: 0;
                        font-weight: 500;
                        font-family: "Lato-Regular";

                        strong {
                            color: #00a4dd;
                            font-weight: 600;
                            font-family: "Lato-Regular";
                        }

                    }

                    p {
                        font-size: 12px;
                        line-height: 17px;
                        color: #003049;
                        margin-bottom: 0;
                        font-weight: 500;
                        font-family: "Lato-Regular"
                    }


                }
            }
        }

    }

    .takeaway {
        .takeaway-block {
            display: flex;
            justify-content: center;
            // align-items: center;
            margin-bottom: 70px;
            margin-top: 15px;

            .home-banner-img {
                img {
                    margin-right: 11px;
                    width: 284px;
                }
            }

            .benefits-box {
                background-color: transparent;
                width: 55%;
                margin-left: 40px;

                p {
                    color: #003049;
                    font-size: 18px;
                    line-height: 28px;
                    font-weight: 500;
                    margin-bottom: 16px;
                    font-family: "Lato-Regular";
                }

                h3 {
                    font-size: 14px;
                    color: #003049;
                    line-height: 28px;
                    font-family: Co Headline Bold;
                    margin-bottom: 18px;
                    font-weight: bold;

                    strong {
                        color: #db1928;
                    }
                }

                .merchant-number{
                    font-size: 24px;
                }

                .get-in-btn {
                    button {
                        &.primary-btn {
                            background-color: #db1928;
                            border-color: #db1928;
                            width: 60%;
                            border-radius: 50px;
                            border: 0;
                            margin-bottom: 0;
                            height: 50px;
                            display: flex;
                            color: #fff;
                            margin-left: 0;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                opacity: 0.8;
                            }
                        }

                        img {
                            width: 26px;
                            margin-right: 0px;
                        }
                    }
                }

                .popup {
                    top: 5px;
                    width: 36%;
                    margin: 111px auto;
                    text-align: center;

                    img {
                        width: 113px;
                        margin: 0 auto;
                    }

                    h2 {
                        font-family: Co Headline;
                        margin: 18px 0 24px;
                        padding: 0;
                    }

                    form {
                        .form-group {
                            input {
                                border: 0.5px solid #959595;
                                border-radius: 50px;
                                color: #959595;
                                font-size: 16px;
                                font-style: normal;
                                width: 100%;
                                height: 50px;
                                box-shadow: rgba(14, 11, 11, 0.16) 0px 3px 6px;
                                line-height: 32px;
                                margin-bottom: 1.5rem;
                                padding: 0 1.6rem;
                            }
                        }

                        p {
                            font-size: 12px;
                            margin-bottom: 10px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    .benefits-box {
        position: relative;
        background: #f5f5f5;
        z-index: 0;

        img {
            width: 100%;

            &.benefits-background {
                // background-color: #D62828;
                width: 100%;
                margin: 0px;

            }
        }

        img.benefits-bg {
            position: absolute;
            width: 100%;
            left: 0;
            z-index: -1;
        }

        .benefits-text {
            position: absolute;
            top: 65px;
            left: 0;
            right: 0;
            width: 64%;
            margin: 0 auto;
            text-align: center;

            img {
                width: 16%;
            }

            h2 {
                color: #FCBF49;
                font-family: Co Headline Bold;
                font-size: 34px;
                line-height: 40px;
                text-align: center;
                font-weight: bold;
                margin: 13px 0;
                letter-spacing: 0.3px;
            }

            p {
                color: #fff;
                font-size: 21px;
                margin: 14px 0 29px;
                line-height: 24px;
                font-family: "Lato-Regular";
                font-weight: 500;
            }
        }


        .get-in-btn {

            button {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #f77f00;
                border-radius: 50px;
                background-color: #f77f00;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                font-weight: 600;
                font-family: Lato-Regular;
                width: 332px;
                margin: 0 auto;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                &:hover {
                    opacity: 0.8;
                }

                span {
                    margin-right: 10px;

                    img {
                        width: 100%;
                    }
                }
            }
        }

        .button {
            font-size: 1em;
            padding: 10px;
            color: #fff;
            border-radius: 20px/50px;
            text-decoration: none;
            cursor: pointer;
            transition: all 0.3s ease-out;
        }

        .overlay {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #002f49c3;
            transition: opacity 500ms;
            visibility: hidden;
            opacity: 0;
        }

        .overlay:target {
            visibility: visible;
            opacity: 1;
            overflow: auto;
        }

        .popup {
            margin: 70px auto;
            padding: 20px;
            background: #fff;
            top: 30%;
            border-radius: 20px;
            width: 277px;
            margin: 0 auto;
            position: relative;
            transition: all 3s ease-in-out;

            img {
                width: 90px;
                position: relative;
                top: 72%;
            }

            h2 {
                margin-top: 0;
                padding: 8px 0 0;
                color: #c1272d;
                font-size: 24px;
                font-weight: bold;
            }

            p {
                color: #002333;
                font-size: 15px;
                font-weight: 500;
            }

            .share-icon {
                color: #fff;
                font-size: 17px;
                text-decoration: none;
                border: 2px solid #D62828;
                border-radius: 50px;
                width: 100%;
                background-color: #D62828;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                font-weight: 500;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                a {
                    text-decoration: none;
                    color: #fff;
                }

                img {
                    width: 18px;
                    margin-right: 10px;
                }
            }

            ul {
                display: flex;
                padding: 0;
                align-items: center;
                justify-content: center;
                margin: 16px 0 0;

                img {
                    width: 50px;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .close {
                position: absolute;
                top: 0px;
                right: 14px;
                transition: all 200ms;
                font-size: 30px;
                font-weight: bold;
                text-decoration: none;
                color: #333;
            }

            .close:hover {
                color: #003049;
            }

            .content {
                max-height: 30%;
                overflow: auto;
            }
        }
    }

    .questlonnalre-from {
        form {
            margin: 0 7rem 1.5rem;

            p {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
            }

            .form-group {
                input {
                    border: 0.5px solid #959595;
                    border-radius: 50px;
                    color: #959595;
                    font-size: 16px;
                    font-style: normal;
                    width: 100%;
                    height: 50px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    line-height: 32px;
                    margin-bottom: 1.5rem;
                    padding: 0 1.6rem;
                }

                label {
                    display: block;
                    padding-bottom: 20px;
                    font-size: 21px;
                    line-height: 28px;
                    font-weight: bold;
                    font-family: "Lato-Regular";
                    color: #003049;
                }

                input:-webkit-autofill {
                    transition: background-color 9999s ease-in-out 0s;
                    -webkit-text-fill-color: var(--text-black) !important;
                }

                input:focus-visible {
                    outline: 0;
                }

                span {
                    position: absolute;
                    margin: 13px 0;
                    right: 33%;
                }
            }

        }

        .form-group {
            margin: 0 8rem 0rem;

            input {
                border: 0.5px solid #959595;
                border-radius: 50px;
                color: #959595;
                font-size: 16px;
                font-style: normal;
                width: 100%;
                height: 60px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                line-height: 32px;
                margin-bottom: 1.5rem;
                padding: 0 1.6rem;
                font-family: "Lato-Regular";
                font-weight: 400;
            }

            label {
                display: block;
                padding-bottom: 20px;
                font-size: 21px;
                line-height: 28px;
                font-weight: bold;
                font-family: "Lato-Regular";
                color: #003049;
            }

            input:-webkit-autofill {
                transition: background-color 9999s ease-in-out 0s;
                -webkit-text-fill-color: var(--text-black) !important;
            }

            input:focus-visible {
                outline: 0;
            }
        }

        .get-in-btn {
            margin: 0.7rem 0 0rem;
            padding-bottom: 3rem;

            button {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #D62828;
                border-radius: 50px;
                width: 30%;
                background-color: #D62828;
                display: flex;
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                font-family: "Lato-Regular";
                font-weight: 900;
                height: 55px;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                &:hover {
                    opacity: 0.8;
                }

                span {

                    img {
                        width: 28px;
                        filter: brightness(0) invert(1);
                        margin-right: 8px;
                    }
                }
            }
        }

        .questlonnalre-tab {
            margin: 0 8rem 0rem;

            .tab-list {
                label {
                    display: block;
                    padding-bottom: 31px;
                    font-size: 21px;
                    line-height: 32px;
                    font-weight: bold;
                    font-family: "Lato-Regular";
                    color: #003049;
                }

                input {
                    border: 0.5px solid #959595;
                    border-radius: 50px;
                    color: #959595;
                    font-size: 16px;
                    font-style: normal;
                    width: 100%;
                    height: 60px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    line-height: 32px;
                    margin-bottom: 1.5rem;
                    padding: 0 1.6rem;
                    outline: none;
                }

                ul {
                    padding: 0;
                    margin-bottom: 0;

                    li {
                        display: inline-flex;
                        align-items: center;
                        list-style: none;
                        color: #D62827;
                        border: 2px solid #D62827;
                        border-radius: 63px;
                        font-size: 16px;
                        font-family: "Lato-Regular";
                        padding: 5px 35px;
                        min-height: 60px;
                        font-weight: bold;
                        box-shadow: 0px 3px 6px #00000029;
                        cursor: pointer;
                        margin-right: 10px;
                        margin-bottom: 12px;
                        background-color: #fff;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.active {
                            background-color: #D62827;
                            color: #fff;
                            border-radius: 50px;

                        }

                        a {
                            text-decoration: none;
                            color: #D62827;
                            cursor: pointer;
                        }

                        &:hover {
                            background-color: #D62827;
                            color: #fff;
                            border-radius: 50px;
                        }
                    }
                }

                .section-list {
                    margin-bottom: 16px;

                    p {
                        color: #d62828;
                        font-size: 17px;
                        line-height: 22px;
                        margin-bottom: 0;
                    }
                }

                .desktop-slider {
                    .swiper {
                        .swiper-wrapper {
                            .swiper-slide {
                                >div {
                                    margin-right: 10px;
                                    cursor: pointer;

                                    &:last-child {
                                        margin-right: 0;
                                    }

                                    h3 {
                                        font-size: 1.75rem;
                                        background: #fff;
                                        width: 110px;
                                        height: 110px;
                                        border-radius: 78px;
                                        display: flex;
                                        align-items: center;
                                        position: relative;
                                        text-align: center;
                                        justify-content: center;
                                        // z-index: -1;
                                        margin: 0 auto 0rem;
                                        border: 3px solid #d62828;
                                    }

                                    p {
                                        text-align: center;
                                        font-family: "Lato-Regular";
                                        font-weight: bold;
                                        color: #003049;
                                    }


                                    &.active {
                                        h3 {
                                            background-color: #d62828;

                                            img {
                                                filter: brightness(0) invert(1);
                                            }
                                        }

                                        p {
                                            color: #d62828;
                                        }
                                    }
                                }

                                &:nth-child(1) {
                                    h3 {
                                        img {
                                            width: 50px;
                                            filter: invert(40%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(80%) contrast(122%);
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    h3 {
                                        img {

                                            filter: invert(40%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(80%) contrast(122%);
                                        }
                                    }
                                }

                                &:nth-child(5) {
                                    h3 {
                                        img {

                                            filter: invert(40%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(80%) contrast(122%);
                                        }
                                    }
                                }
                            }
                        }
                    }




                    span {
                        color: #d62828;
                        font-size: 17px;
                        line-height: 22px;
                        margin-bottom: 0;
                        position: relative;
                        top: -22px;
                        display: block;
                    }
                }

                .desktop-slider-mobile {
                    span {
                        display: none;
                    }
                }
            }

            .swiper {
                width: 100%;
                height: 100%;
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .swiper-wrapper {
                display: flex;
            }

            .swiper-slide {
                // text-align: center;

                /* Center slide text vertically */
                display: flex;
                justify-content: center;
                // align-items: center;
            }

            .swiper-slide img {
                display: block;
                width: auto;
                height: auto;
                // object-fit: cover;
            }


        }
    }

    .awesome-box {
        background-color: #f5f5f5;
        padding: 50px 20rem;

        .sumo-img {
            width: 23%;
        }

        h2 {
            color: #D62828;
            font-family: Co Headline Bold;
            font-size: 34px;
            line-height: 50px;
            margin: 10px 0 10px;
            font-weight: bold;
        }

        h4 {
            color: #003049;
            font-family: Co Headline Bold;
            font-size: 24px;
            line-height: 30px;
            font-weight: bold;

            strong {
                color: #D62828;
            }
        }

        form {
            margin: 0 0rem;

            p {
                margin-bottom: 0;
                font-size: 14px;
                font-weight: 500;
                font-family: "Lato-Regular";
                line-height: 20px;
            }

            .form-group {
                margin-bottom: 1.5rem;

                input {
                    border: 0.5px solid #959595;
                    border-radius: 50px;
                    color: #959595;
                    font-size: 16px;
                    font-style: normal;
                    width: 100%;
                    height: 50px;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                    line-height: 32px;
                    font-weight: 500;
                    font-family: "Lato-Regular";
                    text-transform: capitalize;

                    padding: 0 1.6rem;
                }

                input:-webkit-autofill {
                    transition: background-color 9999s ease-in-out 0s;
                    -webkit-text-fill-color: var(--text-black) !important;
                }

                input:focus-visible {
                    outline: 0;
                }

                span {
                    position: absolute;
                    margin: 13px 0;
                    right: 33%;
                }

                p {
                    text-align: left;
                    color: #be1622;
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 13px;
                    padding-top: 5px;
                    line-height: 20px;
                }
            }

            .button {
                font-size: 1em;
                padding: 10px;
                color: #fff;
                border-radius: 20px/50px;
                text-decoration: none;
                cursor: pointer;
                transition: all 0.3s ease-out;

            }
        }

        .get-in-btn {
            margin: 0.7rem 0;

            button {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #F77F00;
                border-radius: 50px;
                width: 100%;
                background-color: #F77F00;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Lato-Regular;
                height: 50px;
                font-weight: 600;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                &:hover {
                    opacity: 0.7;
                }

                span {
                    img {
                        margin-right: 8px;
                    }
                }

            }
        }

        p {
            margin-bottom: 0;
            font-size: 12px;
            font-weight: 600;
            font-family: "Lato-Regular";
            line-height: 16px;
            color: #003049;
        }
    }

    .merchants-section {
        background-image: url(../images/one-banner1.png);
        background-color: #d62828;
        width: 100%;
        padding: 80px 0 74px;
        background-repeat: round;

        .text-content {
            h2 {
                font-size: 34px;
                line-height: 50px;
                font-family: Co Headline Bold;
                color: #FCBF49;
                margin-bottom: 50px;
                font-weight: bold;
            }
        }

        .benefits-detail {
            border: 3px solid #FCBF49;
            background-color: #D62828;
            border-radius: 25px;
            margin-bottom: 25px;
            padding: 0 36px;
            height: 271px;

            .benifit-number {
                h3 {
                    font-size: 100px;
                    line-height: 110px;
                    color: #FCBF49;
                    font-family: Co Headline;
                    margin-bottom: 15px;
                    text-shadow: 0px 3px 6px #00000029;
                }
            }

            .benefits-content {
                h5 {
                    color: #fcbf49;
                    font-size: 14px;
                    line-height: 17px;
                    overflow: hidden;
                    max-height: 36px;
                    height: 41px;
                    font-family: "Lato-Regular";
                    font-weight: 600;
                }

                p {
                    font-size: 12px;
                    line-height: 17px;
                    color: #fff;
                    font-family: "Lato-Regular";
                    font-weight: 500;
                }
            }
        }

        .get-in-btn {
            margin-top: 50px;

            button {
                color: #D62828;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #FCBF49;
                border-radius: 50px;
                background-color: #FCBF49;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                width: 332px;
                margin: 0 auto;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                font-family: 'Lato-Black';

                &:hover {
                    opacity: 0.9;
                    color: #fff;
                    background-color: #f7a609;
                    border-color: #f7a609;

                    span {
                        img {
                            filter: brightness(0) invert(1);
                        }
                    }
                }

                span {
                    margin-right: 10px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .merchants-section-slider {
        padding: 75px 0;

        .mobile-slider {
            h2 {
                font-size: 34px;
                line-height: 50px;
                font-family: Co Headline Bold;
                color: #D62828;
                margin-bottom: 60px;
                font-weight: bold;
            }

            .benefits-detail {
                border: 3px solid #00a4dd;
                border-radius: 25px;
                text-align: center;
                margin-right: 13px;
                padding: 30px 41px;
                height: 274px;

                .benifit-number {
                    img {
                        // width: 150px;
                        margin: 0 auto;
                    }
                }

                .benefits-content {
                    h5 {
                        font-size: 18px;
                        line-height: 17px;
                        color: #00a4dd;
                        margin: 20px 0 15px;
                        font-weight: 600;
                        font-family: Lato-Black;
                    }

                    p {
                        font-size: 12px;
                        line-height: 18px;
                        color: #003049;
                        font-weight: 600;
                        margin-bottom: 0;
                        font-family: Lato-Regular;
                    }
                }
            }

            .slick-slide {
                &:nth-child(2) {
                    .benefits-detail {
                        border-color: #019244;

                        .benefits-content {
                            h5 {
                                color: #019244;
                            }
                        }
                    }
                }
            }

            .slick-slide {
                &:nth-child(3) {
                    .benefits-detail {
                        border-color: #e0186a;

                        .benefits-content {
                            h5 {
                                color: #e0186a;
                            }
                        }
                    }
                }
            }

            .slick-slide {
                &:nth-child(5) {
                    .benefits-detail {
                        border-color: #019244;

                        .benefits-content {
                            h5 {
                                color: #019244;
                            }
                        }
                    }
                }
            }

            .slick-slide {
                &:nth-child(6) {
                    .benefits-detail {
                        border-color: #e0186a;

                        .benefits-content {
                            h5 {
                                color: #e0186a;
                            }
                        }
                    }
                }
            }

            .slick-next:before {
                color: #A3A3A3;
                opacity: 100%;
            }

            .slick-prev:before {
                color: #A3A3A3;
                opacity: 100%;
            }
        }

        .get-in-btn {
            margin-top: 60px;

            button {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #D62828;
                border-radius: 50px;
                background-color: #D62828;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                font-weight: 600;
                width: 32%;
                margin: 0 auto;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                font-family: 'Lato-Regular';

                &:hover {
                    opacity: 0.8;
                }

                span {
                    margin-right: 10px;

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    .benefits-box.merchants-box {
        background-color: transparent;
    }



}

.share-popup-modal {
    .modal-dialog {
        width: 20%;

        .modal-content {
            border-radius: 25px;

            .modal-header {
                border-bottom: 0;
                position: relative;

                button {
                    &.btn-close {
                        position: absolute;
                        top: 18px;
                        right: 18px;

                        &.hover {
                            opacity: 100%;
                        }
                    }
                }
            }

            .modal-body {
                padding: 15px 15px 36px;

                .share-popup-modal-inner {
                    text-align: center;

                    .share-popup {
                        h2 {
                            margin-top: 0;
                            padding: 8px 0 0;
                            color: #c1272d;
                            font-size: 24px;
                            font-family: Co Headline;
                            font-weight: bold;
                        }

                        p {
                            color: #002333;
                            font-size: 15px;
                            font-weight: 500;
                        }

                        ul {
                            display: flex;
                            padding: 0;
                            align-items: center;
                            justify-content: center;
                            margin: 16px 0 0;

                            img {
                                width: 50px;
                                margin-right: 10px;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.signup-modal {
    .modal-dialog {
        width: 550px;

        .modal-content {
            border-radius: 25px;

            .modal-header {
                border-bottom: 0;
                position: relative;
                padding: 30px 0 0;

                button {
                    &.btn-close {
                        position: absolute;
                        top: 37px;
                        right: 34px;
                        padding: 0px;
                        /* margin: 0px; */
                        width: 20px;
                        height: 20px;
                        background-image: url(../images/close-btn.svg);
                        opacity: 100%;
                        background-size: 20px;
                        z-index: 9;

                        &.hover {
                            opacity: 100%;
                        }

                        &.btn-close:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            .modal-body {
                padding: 0px 60px 26px;
                position: relative;
                z-index: 0;

                .signup-modal-inner {
                    text-align: center;

                    h2 {
                        margin-top: 14px;
                        padding: 0px 0 0;
                        color: #D62828;
                        font-size: 34px;
                        font-family: Co Headline Bold;
                        margin-bottom: 33px;
                        line-height: 40px;
                        font-weight: bold;
                    }

                    form {
                        .form-group {
                            margin-bottom: 1.5rem;

                            input {
                                border: 0.5px solid #959595;
                                border-radius: 50px;
                                color: #959595;
                                font-size: 16px;
                                font-style: normal;
                                width: 100%;
                                height: 50px;
                                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                                line-height: 32px;
                                padding: 0 1.6rem;
                                outline: none;
                                font-weight: 500;
                                font-family: "Lato-Regular";
                            }

                            p {
                                text-align: left;
                                color: #be1622;
                                font-size: 14px;
                                font-weight: 400;
                                margin-left: 13px;
                                padding-top: 5px;
                                line-height: 20px;
                                margin-top: 0;
                            }
                        }

                        img {
                            width: 113px;
                        }

                    }

                    .primary-btn {
                        background-color: #D62828;
                        border-color: #D62828;
                        width: 100%;
                        border-radius: 50px;
                        border: 0;
                        margin: 10px auto 15px;
                        height: 50px;
                        display: flex;
                        color: #fff;
                        justify-content: center;
                        align-items: center;
                        font-size: 18px;
                        outline: none;
                        box-shadow: 0px 3px 6px #00000029;
                        font-weight: 500;
                        font-family: "Lato-Regular";

                        &:hover {
                            opacity: 0.8;
                        }

                        span {
                            img {
                                margin-right: 7px;
                            }
                        }

                        &:focus-visible {
                            outline: none;
                            box-shadow: none;
                        }
                    }

                    p {
                        margin-bottom: 0;
                        margin-top: 15px;
                        font-size: 12px;
                        color: #003049;
                        line-height: 16px;
                        font-weight: 600;
                        font-family: "Lato-Regular";

                    }

                }
            }
        }
    }
}

@media only screen and (max-device-width: 1366px) {
    .main-wrapper {
        .awesome-box {
            padding: 50px 16rem;

            h2 {
                margin: 10px 0 10px;
            }

            h4 {
                line-height: 30px;
            }

            .sumo-img {
                width: 24%;
            }
        }

        .shadow {
            height: 435px;
        }

        .questlonnalre-from {
            .questlonnalre-tab {
                margin: 0 3rem;

                .slick-slider {
                    .slick-list {
                        .slick-track {
                            .slick-slide {

                                h3 {
                                    width: 100px;
                                    height: 100px;
                                    border: 2px solid #d62828;
                                }
                            }
                        }
                    }
                }
            }

            .form-group {
                margin: 0 3rem 0rem;
            }
        }

        .merchants-section-slider {
            .mobile-slider {
                .benefits-detail {
                    padding: 30px 24px;

                    .benefits-content {
                        p {
                            font-size: 12px;
                            line-height: 18px;
                        }
                    }

                }
            }
        }

        .takeaway {
            .takeaway-block {
                margin-top: -23px;

                .home-banner-img {
                    img {
                        width: 100%;
                    }
                }

                .benefits-box {
                    width: 61%;

                    h3 {
                        margin-bottom: 25px;
                    }
                }
            }
        }

    }

}

@media only screen and (max-width: 1199px) {
    .main-wrapper {
        .home-banner {
            margin: 5px 0;
            top: 154px;

            .home-banner-img {
                position: absolute;
                top: -130px;
                bottom: 100%;

                .banner-icon {
                    width: 85px;
                }

                .camera-icon {
                    width: 24px;
                    top: -35px;
                    right: 30px;
                }
            }

            .my-sumo-button {
                top: 18px;

                h2 {
                    font-size: 35px;
                    width: 51%;
                    line-height: 50px;
                }

                ul {
                    padding: 0.7rem 0 0rem;

                    li {
                        a {
                            width: 150px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .home-img {
            width: 100%;
            height: 420px !important;
            clip-path: circle(72% at 50% -36%) !important;
            transform: translate(0px, -14px) !important;
        }

        .home-link-list {
            .justify-content-center {
                .fixed-tab {
                    ul {
                        li {
                            a {
                                width: 170px;
                            }
                        }
                    }
                }

                .home-list-block {
                    border-radius: 38px;

                    .home-block-img {
                        .social-icon {
                            right: 20px;
                            top: 20px;
                        }

                        .new-btn {
                            top: 20px;
                            left: 20px;
                        }
                    }
                }

                .benefits-boxs {
                    .benefits-text {
                        h2 {
                            font-size: 17px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }

        .benefits-box {
            .benefits-text {
                width: 100%;
                top: 18px;

                img {
                    width: 11%;
                }

                // h2 {
                //     font-size: 20px;
                //     line-height: 26px;
                //     margin: 9px 0 0;
                // }

                // p {
                //     font-size: 13px;
                //     margin: 10px 0 10px;
                //     line-height: 14px;
                // }
            }

            .get-in-btn {
                button {
                    font-size: 13px;
                    height: 45px;
                    width: 291px;

                    span {
                        margin-right: 5px;

                        img {
                            width: 83%;
                        }
                    }
                }
            }
        }

        .awesome-box {
            padding: 50px 10rem;
        }

        .questlonnalre-from {
            .form-group {
                margin: 0 3rem 0rem;
            }

            .get-in-btn {
                button {
                    width: 40%;
                }
            }
        }
    }

    #home1-page {
        .shadow {
            height: 530px !important;
        }
    }
}


@media only screen and (max-width: 991px) {
    .main-wrapper {
        .home-banner {
            margin: 5px 0;
            top: 145px;

            &.questionnainre {
                margin: 0px 0;
                top: 140px;

                .home-banner-img {
                    img {
                        width: 118px;
                    }
                }
            }

            .home-banner-img {
                position: absolute;
                top: -130px;
                bottom: 100%;

                .banner-icon {
                    width: 85px;
                }

                .camera-icon {
                    width: 24px;
                    top: -35px;
                    right: 30px;
                }
            }

            .my-sumo-button {
                top: 26px;
                height: 50px;

                h2 {
                    font-size: 22px;
                    width: 51%;
                    line-height: 31px;
                }

                ul {
                    padding: 0.7rem 0 0rem;

                    li {
                        a {
                            width: 150px;
                            font-size: 12px;
                        }
                    }
                }
            }

            .quest-text {
                h2 {
                    font-size: 22px;
                    width: 51%;
                    line-height: 28px;
                    padding: 0;
                }

                p {
                    font-size: 14px;
                    line-height: 20px;
                    width: 54%;
                    margin: 8px auto 0;
                }
            }
        }

        .shadow {
            height: 312px !important;
        }

        .home-img {
            width: 100%;
            height: 420px !important;
            clip-path: circle(72% at 50% -36%) !important;
            transform: translate(0px, -14px) !important;
        }

        .home-link-list {
            .justify-content-center {
                .fixed-tab {
                    ul {
                        li {
                            a {
                                width: 170px;
                            }
                        }
                    }
                }

                .home-list-block {
                    border-radius: 38px;

                    .home-block-img {
                        .social-icon {
                            right: 20px;
                            top: 20px;
                        }

                        .new-btn {
                            top: 20px;
                            left: 20px;
                        }
                    }
                }

                .benefits-boxs {
                    .benefits-text {
                        h2 {
                            font-size: 17px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }

        .benefits-box {
            .benefits-text {
                width: 100%;
                top: 18px;

                img {
                    width: 11%;
                }

                h2 {
                    font-size: 20px;
                    line-height: 26px;
                    margin: 9px 0 0;
                }

                p {
                    font-size: 13px;
                    margin: 10px 0 10px;
                    line-height: 14px;
                }
            }

            .get-in-btn {
                button {
                    font-size: 13px;
                    height: 45px;
                    width: 291px;

                    span {
                        margin-right: 5px;

                        img {
                            width: 83%;
                        }
                    }
                }
            }
        }

        .awesome-box {
            padding: 50px 10rem;
        }

        .questlonnalre-from {
            .questlonnalre-tab {
                .tab-list {
                    &:nth-child(1) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 710px;
                            }
                        }
                    }

                    &:nth-child(3) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 817px;
                            }
                        }
                    }

                    &:nth-child(5) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 620px;
                            }
                        }
                    }

                    &:nth-child(7) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 717px;
                            }
                        }
                    }
                }
            }
        }
    }

    #home1-page {
        .shadow {
            height: 420px !important;
        }
    }
}

@media only screen and (max-width: 768px) {
    .main-wrapper {
        .home-banner {
            .home-banner-img {
                position: absolute;
                top: 0px;
                bottom: 100%;
            }

            .my-sumo-button {
                ul {
                    padding: 5.7rem 0 0rem;
                }
            }
        }

        .awesome-box {
            padding: 30px 0rem;
        }

        .home-link-list {
            .benefits-box {
                img {
                    width: 100%;
                }

                .benefits-text {
                    width: 100%;
                    top: 66px;

                    h2 {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        .shadow {
            height: 300px !important;
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {

        .home-img {
            height: 500px !important;
            object-fit: cover;
            clip-path: circle(134% at 50% -36%) !important;
            transform: translate(0px, -14px) !important;
        }

        .awesome-box {
            h4 {
                margin-bottom: 26px;
                font-size: 21px;
                line-height: 28px;
            }

            h2 {
                font-size: 30px;
                margin-bottom: 10px;
            }

            form {
                .form-group {
                    margin-bottom: 1.3rem;
                }
            }

            .sumo-img {
                width: 29%;
            }
        }

        .home-banner {
            margin: 0;
            top: 130px;

            &.questionnainre {
                margin: 0px 0;
                top: 99px;

                .home-banner-img {
                    img {
                        width: 151px;
                    }
                }
            }

            .my-sumo-button {
                h2 {
                    font-size: 28px;
                    width: 100%;
                    line-height: 38px;
                    margin: 0px auto 0;
                }
            }

            .quest-text {
                text-align: center;
                margin-top: 5rem;

                h2 {
                    font-size: 26px;
                    width: 90%;
                    line-height: 36px;
                    padding-top: 0;
                }

                p {
                    color: #fff;
                    font-size: 14px;
                    line-height: 18px;
                    width: 90%;
                    margin: 16px auto 0;
                }
            }
        }

        .sumoeats-boxs {
            padding: 0 12px;

            .benefits-box {
                margin-bottom: 40px;

                img.benefits-background {
                    height: 200px;
                    background-color: #D62828;
                    border-radius: 25px;
                }

                .benefits-text {
                    top: 0;
                    bottom: 0;
                    display: flex;
                    align-items: center;

                    h2 {
                        color: #fff;
                        font-size: 21px;
                        line-height: 30px;
                        padding: 0 10px;
                        position: relative;
                        top: -4px;
                    }
                }
            }
        }

        .home-link-list {
            ul {
                display: none;
            }

            &.mobile {
                overflow: auto;

                ul {
                    display: flex;
                    padding-left: 10px;
                    width: 990px;
                    margin: 36px 0;

                    li {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }

                        .bene-item {
                            text-align: center;

                            h5 {
                                font-weight: 600;
                            }

                            p {
                                font-weight: 600;
                            }
                        }
                    }
                }

                &.mobile::-webkit-scrollbar {
                    display: none;
                }

                .sumoeats-box {
                    padding: 0px 10px 36px;

                    .benefits-box {
                        img {
                            width: 100%;

                            &.benefits-background {
                                background-color: #D62828;
                                width: 100%;
                                margin: 0px;
                                object-fit: cover;
                                height: 225px;
                                border-radius: 25px;
                            }
                        }
                    }
                }
            }
        }


        .home-mobile-banner {
            display: block;
        }

        .shadow {
            height: 424px !important;

            .home-img {
                object-fit: none;
                background-color: #d62828;
            }
        }

        .home-link-list {
            .justify-content-center {
                justify-content: flex-start !important;

                .home-list-block {
                    border-radius: 27px;

                    .home-block-img {
                        .social-icon {
                            right: 12px;
                            top: 13px;
                        }

                        .new-btn {
                            left: 12px;
                            top: 14px;
                        }
                    }

                    .home-text {
                        ul {
                            li {
                                .primary-btn-sm {
                                    padding: 0 19px;
                                }
                            }
                        }
                    }
                }

                .home-banner-img {
                    h2 {
                        font-size: 30px;
                        margin: 10px 0;
                    }

                    p {
                        width: 100%;
                        font-size: 14px;
                        line-height: 21px;
                        font-weight: 600;
                    }
                }

                .fixed-tab {
                    margin: -11px;

                    ul {
                        justify-content: flex-start;
                        overflow: auto;
                    }
                }
            }
        }

        .benefits-box {
            background-color: transparent;

            .benefits-text {
                width: 100%;
                top: 53px;

                img {
                    width: 36%;
                }

                h2 {
                    font-size: 30px;
                    margin: 20px 0 0;
                    line-height: 19px;
                    height: 30px;
                }

                p {
                    font-size: 16px;
                    margin: 14px 0 24px;
                    line-height: 24px;
                }
            }

            .get-in-btn {
                a {
                    width: 100%;
                    font-size: 18px;
                }
            }

            img.benefits-background {
                object-fit: cover;
                height: 390px;
            }

            img.benefits-bg {
                object-fit: cover;
                height: 390px;
            }

            .popup {
                width: 337px;
            }

        }

        .questlonnalre-from {
            form {
                margin: 0 12px 2rem;

                p {
                    margin-bottom: 0;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                }

                .form-group {
                    input {
                        font-size: 14px;
                    }

                    label {
                        padding-bottom: 10px;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    span {
                        position: absolute;
                        margin: 13px 0;
                        right: 33%;
                    }
                }


            }

            .form-group {
                margin: 0 0.7rem 0rem;

                label {
                    font-size: 16px;
                    line-height: 23px;
                    padding: 0 0px 16px;
                }

            }

            .get-in-btn {
                margin: 0rem 12px 0px;
                padding-bottom: 32px;

                button {
                    width: 100%;
                    height: 60px;

                    span {
                        img {
                            margin-right: 7px;
                        }
                    }
                }
            }

            .questlonnalre-tab {
                margin: 0 auto;

                .tab-list {
                    label {
                        font-size: 16px;
                        line-height: 23px;
                        padding: 0 12px 16px;
                    }

                    .section-list {
                        overflow: auto;
                        padding: 0 12px;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        ul {
                            display: flex;
                            width: 716px;
                            margin-bottom: 10px;

                            li {
                                height: auto;
                                padding: 0px 30px;
                                margin-bottom: 0;
                                background-color: #fff;
                                color: #D62828;

                                &.active {
                                    background-color: #d62828;
                                    color: #fff;
                                }
                            }

                            .section-list {
                                p {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }

                    .desktop-slider {
                        span {
                            margin-left: 13px;
                        }
                    }


                    .mobile-slider {
                        .slick-slider {
                            button {
                                display: none !important;
                            }
                        }
                    }
                }

                .tab-list {
                    &:nth-child(3) {
                        .section-list {
                            ul {
                                width: 777px;
                            }
                        }
                    }

                    &:nth-child(4) {
                        .section-list {
                            ul {
                                width: 521px;
                            }
                        }
                    }

                    &:nth-child(5) {
                        .section-list {
                            ul {
                                width: 580px;
                            }
                        }
                    }

                    &:nth-child(6) {
                        .section-list {
                            ul {
                                width: 350px;
                            }
                        }
                    }

                    &:nth-child(7) {
                        .section-list {
                            ul {
                                width: 687px;
                            }
                        }
                    }

                    &:nth-child(8) {
                        .section-list {
                            ul {
                                width: 350px;
                            }
                        }
                    }

                    &:nth-child(9) {
                        .section-list {
                            ul {
                                width: 516px;
                            }
                        }
                    }

                    &:nth-child(10) {
                        .section-list {
                            ul {
                                width: 350px;
                            }
                        }
                    }


                    .desktop-slider-mobile {
                        overflow: auto;
                        padding: 0 12px;

                        &::-webkit-scrollbar {
                            display: none;
                        }

                        >div {
                            >div {
                                margin-right: 10px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                h3 {
                                    font-size: 1.75rem;
                                    background: #fff;
                                    width: 110px;
                                    height: 110px;
                                    border-radius: 78px;
                                    display: flex;
                                    align-items: center;
                                    position: relative;
                                    text-align: center;
                                    justify-content: center;
                                    // z-index: -1;
                                    margin: 0 auto 0rem;
                                    border: 3px solid #d62828;
                                }

                                p {
                                    text-align: center;
                                    font-family: "Lato-Regular";
                                    font-weight: bold;
                                    color: #003049;
                                }

                                &:nth-child(1) {
                                    h3 {
                                        img {
                                            width: 50px;
                                            filter: invert(40%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(80%) contrast(122%);
                                        }
                                    }
                                }

                                &:nth-child(3) {
                                    h3 {
                                        img {

                                            filter: invert(40%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(80%) contrast(122%);
                                        }
                                    }
                                }

                                &:nth-child(5) {
                                    h3 {
                                        img {

                                            filter: invert(40%) sepia(98%) saturate(7376%) hue-rotate(0deg) brightness(80%) contrast(122%);
                                        }
                                    }
                                }

                                &.active {
                                    h3 {
                                        background-color: #d62828;

                                        img {
                                            filter: brightness(0) invert(1);
                                        }
                                    }

                                    p {
                                        color: #d62828;
                                    }
                                }
                            }

                        }

                        span {
                            color: #d62828;
                            font-size: 17px;
                            line-height: 22px;
                            margin-bottom: 0;
                            position: relative;
                            top: -22px;
                            display: block;
                        }

                    }

                    .desktop-slider {
                        span {
                            display: none;
                        }
                    }
                }
            }

            .questlonnalre-tab-takeaway {
                .tab-list {
                    input {
                        width: 94%;
                        margin: 0 auto 1.6rem;
                        position: relative;
                        left: 0;
                        right: 0;
                        display: flex;
                    }

                    &:nth-child(1) {
                        .section-list {
                            ul {
                                width: 928px;
                            }
                        }
                    }

                    &:nth-child(3) {
                        .section-list {
                            ul {
                                width: 1267px;
                            }
                        }
                    }

                    &:nth-child(4) {
                        .section-list {
                            ul {
                                width: 685px;
                            }
                        }
                    }

                    &:nth-child(6) {
                        .section-list {
                            ul {
                                width: 300px;
                            }
                        }
                    }

                    &:nth-child(7) {
                        .section-list {
                            ul {
                                width: 578px;
                            }
                        }
                    }
                }
            }

            .container {
                padding: 0;
            }
        }

        .takeaway {
            .takeaway-block {
                display: block;
                justify-content: center;
                // align-items: center;
                margin-bottom: 30px;
                margin-top: -5px;

                .home-banner-img {
                    margin-bottom: 21px;

                    img {
                        margin: 0px auto;
                        width: 40%;
                    }
                }

                .benefits-box {
                    width: 100%;
                    margin-left: 0px;

                    p {
                        font-size: 18px;
                        line-height: 21px;
                        margin-bottom: 18px;
                        text-align: center;
                    }

                    h3 {
                        text-align: center;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }

                    .get-in-btn {
                        button {
                            &.primary-btn {
                                margin: 0 auto 0px;
                                width: 100%;
                                box-shadow: 0px 3px 6px #00000029;
                            }

                        }
                    }
                }
            }

            .home-banner-img {
                margin-bottom: 21px;
                text-align: center;

                img {
                    margin: 0px auto;
                    width: 31%;
                }
            }
        }

        .shadow2 {
            // z-index: -1;
            content: "";
            height: 307px;
            -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
            filter: drop-shadow(1px 8px 8px rgba(33, 22, 0, 0.4));
            position: relative;

            .home-img2 {
                width: 100%;
                clip-path: circle(142% at 50% -65%);
                transform: translate(0px, 0px);
                object-fit: none;
                height: 300px;
                background-color: #d62828;

                img {
                    object-fit: cover;
                }
            }
        }

        .merchants-section {
            padding: 35px 0 49px;
            background-size: contain;
            background-image: url(../images/one-banner-mobile.png);

            .text-content {
                h2 {
                    font-size: 24px;
                    line-height: 30px;
                    /* font-family: Co Headline; */
                    /* color: #FCBF49; */
                    margin-bottom: 42px;
                    padding: 0 20px;
                }
            }

            .benefits-detail {
                margin-bottom: 0px;
                padding: 0 30px;
                text-align: center;
                margin-right: 18px;
            }

            .get-in-btn {
                margin-top: 50px;

                button {
                    font-weight: 500;
                    width: 100%;

                    span {
                        margin-right: 10px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            .mobile-slider {
                overflow: auto;
                margin: 0 -12px;
                padding: 0 2px 0px 12px;

                &::-webkit-scrollbar {
                    display: none;
                }

                >div {
                    .benefits-detail {
                        width: 271px;
                        height: 278px;
                        display: grid;
                        align-content: center;
                        justify-items: center;
                        justify-content: center;
                        align-items: center;

                        .benifit-number {
                            img {
                                vertical-align: middle;
                                margin-bottom: 10px;
                                width: 100%;
                                height: 92px;
                            }
                        }

                    }

                    &:nth-child(8) {
                        .benefits-detail {
                            margin-right: 0;
                        }
                    }
                }
            }
        }

        .merchants-section-slider {
            padding: 50px 0 53px;

            .mobile-slider {
                h2 {
                    font-size: 24px;
                    line-height: 30px;
                    margin-bottom: 37px;
                    padding: 0 10px;
                }

                .benefits-detail {
                    text-align: center;
                    margin-right: 14px;
                    height: auto;

                }

                .slick-next {
                    display: none !important;
                }

                .slick-prev {
                    display: none !important;
                }

                .slick-slider {
                    margin: 0 -12px;

                    .slick-list {
                        padding: 0 14px;
                    }
                }

                .slick-slide {
                    &:nth-child(6) {
                        .benefits-detail {
                            margin-right: 16px;
                        }
                    }
                }

                .scroll-slider {
                    overflow: auto;
                    margin: 0 -12px;
                    padding: 0 2px 0px 12px;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    >div {
                        .benefits-detail {
                            width: 271px;
                            height: 282px;

                            .benefits-content {
                                h5 {
                                    margin: 10px 0;
                                }
                            }
                        }

                        &:nth-child(2) {
                            .benefits-detail {
                                border-color: #019244;

                                .benefits-content {
                                    h5 {
                                        color: #019244;
                                    }
                                }
                            }
                        }

                        &:nth-child(5) {
                            .benefits-detail {
                                border-color: #019244;

                                .benefits-content {
                                    h5 {
                                        color: #019244;
                                    }
                                }
                            }
                        }

                        &:nth-child(3) {
                            .benefits-detail {
                                border-color: #e0196a;

                                .benefits-content {
                                    h5 {
                                        color: #e0196a;
                                    }
                                }
                            }
                        }

                        &:nth-child(6) {
                            .benefits-detail {
                                border-color: #e0196a;
                                margin-right: 0;

                                .benefits-content {
                                    h5 {
                                        color: #e0196a;
                                    }
                                }
                            }
                        }

                    }
                }
            }

            .get-in-btn {
                margin-top: 37px;

                button {
                    width: 100%;

                    span {
                        margin-right: 10px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    #home1-page {
        .shadow {
            height: 430px !important;
        }
    }

    .share-popup-modal {
        .modal-dialog {
            width: 96%;
            position: fixed;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
        }
    }

    .signup-modal {
        padding-left: 0 !important;

        .modal-dialog {
            margin: 0 auto;
            width: 95%;
            right: 0;
            left: 0;
            top: 0;
            bottom: 5px;
            display: flex;
            align-items: center;
            // position: fixed;

            .modal-content {

                // margin: 0 12px;
                .modal-header {
                    button {
                        &.btn-close {
                            top: 28px;
                            right: 26px;
                        }
                    }
                }

                .modal-body {
                    padding: 0px 13px 26px;

                    .signup-modal-inner {

                        h2 {
                            font-size: 24px;
                            margin-bottom: 18px;
                            line-height: 36px;
                            margin-top: 8px;
                        }

                        form {
                            .form-group {
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-device-width: 430px) {
    .main-wrapper {
        .shadow2 {
            // z-index: -1;
            content: "";
            height: 356px;
            -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
            filter: drop-shadow(1px 8px 8px rgba(33, 22, 0, 0.4));
            position: relative;

            .home-img2 {
                width: 100%;
                clip-path: circle(142% at 50% -65%);
                transform: translate(0px, 0px);
                object-fit: none;
                height: 330px;

                img {
                    object-fit: cover;
                }
            }
        }

        .shadow {
            height: 433px !important;

            .home-img {
                height: 427px !important;
            }
        }

        .takeaway {
            .my-sumo-button {
                h2 {
                    margin: 0 28px;
                    line-height: 34px !important;
                    padding-top: 24px;
                }
            }
        }

        .home-banner {
            top: 99px;

            &.landing-user {
                top: 130px;
            }

            .quest-text {
                h2 {
                    width: 78%;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    width: 84%;
                    margin: 14px auto 0;
                }
            }
        }

        .merchants-section-slider {
            .mobile-slider {
                .benefits-detail {
                    padding: 30px 20px;

                    .benefits-content {
                        p {
                            font-size: 14px;
                            line-height: 22px;
                        }
                    }

                }
            }
        }

        .sumoeats-boxs {
            .benefits-box {
                .benefits-text {
                    top: 0px;
                    bottom: 0;
                    display: flex;
                    align-items: center;

                    h2 {
                        color: #fff;
                        font-size: 21px;
                        line-height: 30px;
                        padding: 0 10px;
                        position: relative;
                        top: -5px;
                        height: auto;
                        margin-top: 0;
                    }
                }
            }
        }

        .benefits-box {
            .get-in-btn {
                button {
                    font-size: 18px;
                    line-height: 29px;
                    font-family: Lato-Regular;
                    font-weight: 500;
                    height: 50px;

                    span {
                        img {
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-device-width: 390px) {
    .main-wrapper {

        .shadow {
            height: 423px !important;

            .home-img {
                height: 470px !important;
            }
        }

        .takeaway {
            .my-sumo-button {
                h2 {
                    margin: 0 10px;
                }
            }
        }

        .home-banner {
            top: 99px;

            &.landing-user {
                top: 130px;
            }

            .quest-text {
                h2 {
                    width: 85%;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    width: 84%;
                    margin: 14px auto 0;
                }
            }
        }

        .shadow2 {
            // z-index: -1;
            content: "";
            height: 330px;
            -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 100% 100%);
            filter: drop-shadow(0px 0px 0px rgba(33, 22, 0, 0));
            position: relative;

            .home-img2 {
                width: 100%;
                clip-path: circle(142% at 50% -65%);
                transform: translate(0px, 0px);
                object-fit: none;
                height: 330px;

                img {
                    object-fit: cover;
                }
            }
        }

        .merchants-section-slider {
            .mobile-slider {
                .benefits-detail {
                    padding: 30px 20px;

                    .benefits-content {
                        p {
                            font-size: 12px;
                            line-height: 18px;
                        }

                        h5 {
                            line-height: 41px;
                            height: auto;
                            max-height: auto;
                        }
                    }

                }
            }
        }

        .merchants-section {
            .benefits-detail {
                padding: 30px 30px;

                .benifit-number {
                    img {
                        margin-bottom: 3px;
                    }

                    h3 {
                        width: auto;
                        margin: 0 auto 20px;
                        height: 70px;
                        font-size: 100px;
                        line-height: 100px;
                    }
                }

                .benefits-content {
                    padding-top: 3px;
                }
            }

        }

        .merchants-section {
            .benefits-detail {
                .benefits-content {
                    h5 {
                        // line-height: 41px;
                        height: auto;
                        max-height: none;
                        margin-bottom: 17px;
                    }
                }
            }
        }

        .benefits-box {
            .benefits-text {
                h2 {
                    line-height: 31px;
                }
            }
        }

        .sumoeats-boxs {
            .benefits-box {
                .benefits-text {
                    h2 {
                        padding: 0 14px;
                        height: auto;
                        margin: 0;
                        top: -2px;
                    }
                }
            }
        }

        .questlonnalre-from {
            .questlonnalre-tab {
                .tab-list {
                    &:nth-child(1) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 770px;
                            }
                        }
                    }

                    &:nth-child(2) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 837px;
                            }
                        }
                    }

                    &:nth-child(3) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 1380px;
                            }
                        }
                    }

                    &:nth-child(4) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 775px;
                            }
                        }
                    }

                    &:nth-child(5) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 744px;
                            }
                        }
                    }

                    &:nth-child(5) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 620px;
                            }
                        }
                    }

                    &:nth-child(7) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 745px;
                            }
                        }
                    }

                    &:nth-child(9) {
                        .section-list {
                            overflow: auto;

                            &::-webkit-scrollbar {
                                display: none;
                            }

                            ul {
                                width: 550px;
                            }
                        }
                    }
                }
            }
        }

        .home-link-list {
            .justify-content-center {
                .home-banner-img {
                    h2 {
                        font-size: 28px;
                    }
                }
            }
        }

    }
}

@media only screen and (max-device-width: 375px) {
    .main-wrapper {
        .shadow {
            height: 438px !important;

            .home-img {
                height: 512px !important;
            }
        }

        .takeaway {
            .my-sumo-button {
                h2 {
                    padding-top: 0px;
                }
            }
        }

        .home-banner {
            top: 99px;

            .quest-text {
                h2 {
                    width: 89%;
                }

                p {
                    font-size: 16px;
                    line-height: 24px;
                    width: 84%;
                    margin: 14px auto 0;
                }
            }
        }
    }
}