.footer {
    background: #003049;
    padding: 40px 0;

    .justify-content-center {
        align-items: flex-start !important;

        .footer-logo {
            display: flex;
            align-items: center;
            margin: 22px 0;

            img {
                width: 238px;
            }
        }

        .footer-text {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                text-align: left;

                h3 {
                    color: #FCBF49;
                    font-size: 14px;
                    line-height: 25px;
                    font-weight: bold;
                    font-family: "Lato-Regular";
                }

                li {
                    color: #fff;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 25px;
                    font-family: "Lato-Regular";
                    a {
                        text-decoration: none;
                        color: #fff;

                        img {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }

        .footer-icon {
            ul {
                padding: 0;
                margin: 40px 0;
                list-style: none;
                text-align: center;

                li {
                    display: inline-block;
                    margin-right: 32px;
                    a {
                        img:hover {
                            opacity: 0.8;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {
    .footer {
        .justify-content-center {
            display: block !important;

            .footer-logo {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 238px;
                }
            }

            .footer-text {
                ul {
                    margin: 13px 0;
                    text-align: center;

                    li {
                        color: #fff;
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 25px;
                    }
                }
            }

            .footer-icon {
                ul {
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .footer {
        background: #003049;
        padding: 40px 0;

        .justify-content-center {
            .footer-logo {
                justify-content: center;

                img {
                    width: 252px;
                }
            }

            .footer-text {
                ul {
                    text-align: center;
                    margin-top: 1.5rem;

                    li {
                        font-weight: 500;
                        
                    }
                }
            }

            .footer-icon {
                ul {
                    text-align: center;
                    margin: 2rem 0 0.5rem;

                    li {
                        display: inline-block;
                        margin-right: 32px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}