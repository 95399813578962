// @import '/font-family:  Co Headline, sans-serif;';
@import "header.scss";
@import "footer.scss";
@import "home.scss";
@import "home1.scss";
@import "signup.scss";
@import "signin.scss";
@import "account.scss";
@import "marketing.scss";
@import "upload.scss";
@import "varification.scss";
@import "deals-details.scss";
@import "thankyou.scss";

.loader {
  z-index: 99;
  justify-content: center;
  align-items: center;
}