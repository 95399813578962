.main-wrapper {
    .signup-banner {
        .my-sumo-button {
            ul {
                li {
                    display: inline-block;
                    margin-right: 10px;

                    .primary-btn-sm {
                        color: #D62828;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #D62828;
                        border-radius: 50px;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-weight: 500;

                        &:hover {
                            background: #D62828;
                            color: #fff;
                        }
                    }

                    .primary-btn {
                        color: #F77F00;
                        font-size: 15px;
                        text-decoration: none;
                        border: 2px solid #F77F00;
                        border-radius: 50px;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 50px;
                        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
                        font-weight: 500;

                        &:hover {
                            background: #F77F00;
                            color: #fff;
                        }

                        &.active {
                            background: #F77F00;
                            color: #fff;
                        }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .let-in-btn {
            margin: 1.5rem 0;

            button {
                color: #fff;
                font-size: 15px;
                text-decoration: none;
                border: 2px solid #F77F00;
                border-radius: 50px;
                width: 100%;
                background-color: #F77F00;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 50px;
                font-weight: 500;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

                span {
                    margin-left: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .main-wrapper {
        .signup-banner {
            .my-sumo-button {
                p {
                    font-size: 24px;
                    line-height: 30px;
                    width: 100%;
                }

                ul {
                    li {
                        .primary-btn-sm {
                            width: 170px;
                        }

                        .primary-btn {
                            width: 170px;
                        }
                    }
                }
            }

            // form {
            //     margin: 0 0rem;
            //     .form-group {
            //         input {
            //             margin-bottom: 1.5rem;
            //         }
            //         span {
            //             right: 9%;
            //         }
            //     }
            //     .form-check {
            //         padding: 0;
            //         p {
            //             font-size: 16px;
            //             margin: 1rem 0;
            //             a {
            //                 font-weight: 600;
            //                 color: #444444;
            //             }
            //         }
            //     }
            //     .let-in-btn {
            //         margin: 1.5rem 0;
            //         button {
            //             color: #fff;
            //             font-size: 15px;
            //             text-decoration: none;
            //             border: 2px solid #F77F00;
            //             border-radius: 50px;
            //             width: 100%;
            //             background-color: #F77F00;
            //             display: flex;
            //             align-items: center;
            //             justify-content: center;
            //             height: 50px;
            //             font-weight: 500;
            //             box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
            //             span {
            //                 margin-left: 10px;
            //             }
            //         }
            //     }
            // }
        }
    }

    #signup-page {
        padding: 2rem 0;
    }
}